import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smart-auth',
  templateUrl: './auth.component.html',
  styles: [],
})
export class AuthComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
