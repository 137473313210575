import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from '../../../../auth/authentication.service';
import {
  SYSTEM_NAME_ALFRED,
  SYSTEM_NAME_EPC,
  SYSTEM_NAME_ETD, SYSTEM_NAME_HELIOS,
  SYSTEM_NAME_HELPDESK,
  SYSTEM_NAME_ISBN,
  SYSTEM_NAME_LIBADMIN,
  SYSTEM_NAME_ROOMBOOK,
  SYSTEM_NAME_TOOLS,
  SYSTEM_NAME_UIK,
  SYSTEM_NAME_ZP
} from "../../../../../+app-custom/constants";
import { AppRedirectService } from '../../../../auth/app-redirect.service';

@Component({
  selector: 'smart-shortcut-modal',
  templateUrl: './shortcut-modal.component.html',
  styles: [
    `
      .icon-stack__image {
        width: 40%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 0;
      }
    `,
  ],
})
export class ShortcutModalComponent {
  menuItems = [
    {
      title: SYSTEM_NAME_EPC,
      icon: 'assets/img/custom/epc.png',
    },
    {
      title: SYSTEM_NAME_ETD,
      icon: 'assets/img/custom/etd.png',
    },
    {
      title: SYSTEM_NAME_HELIOS,
      icon: 'assets/img/custom/others.png',
    },
    {
      title: SYSTEM_NAME_ISBN,
      icon: 'assets/img/custom/isbn.png',
    },
    {
      title: SYSTEM_NAME_HELPDESK,
      icon: 'assets/img/custom/helpdesk.png',
    },
    {
      title: SYSTEM_NAME_ROOMBOOK,
      icon: 'assets/img/custom/roombook.png',
    },
    {
      title: SYSTEM_NAME_ZP,
      icon: 'assets/img/custom/zp.png',
    },
    {
      title: SYSTEM_NAME_UIK,
      icon: 'assets/img/custom/uik.png',
    },
    {
      title: SYSTEM_NAME_LIBADMIN,
      icon: 'assets/img/custom/libadmin.png',
    },
    {
      title: SYSTEM_NAME_ALFRED,
      icon: 'assets/img/custom/others.png',
    },
  ];

  constructor(public bsModalRef: BsModalRef, private appRedirectService: AppRedirectService) {}

  closeShortcut($event: MouseEvent) {
    this.bsModalRef.hide();
  }

  // Redirects

  redirectEpc() {
    this.appRedirectService.redirectEpc();
  }

  redirectEtd() {
    this.appRedirectService.redirectEtd();
  }

  redirectIsbn() {
    this.appRedirectService.redirectIsbn();
  }

  redirectRoombook() {
    this.appRedirectService.redirectRoombook();
  }

  redirectZp() {
    this.appRedirectService.redirectZp();
  }

  redirectUik() {
    this.appRedirectService.redirectUik();
  }

  redirectHelpdesk() {
    this.appRedirectService.redirectHelpdesk();
  }

  redirectAlfred() {
    this.appRedirectService.redirectAlfred();
  }

  redirectHelios() {
    this.appRedirectService.redirectHelios();
  }

  redirectTools() {
    this.appRedirectService.redirectTools();
  }

  redirectLibadmin() {
    this.appRedirectService.redirectLibadmin();
  }

  // Permission check

  isEpcAllowed() {
    return AuthenticationService.checkSystemAccessPermission(SYSTEM_NAME_EPC);
  }

  isEtdAllowed() {
    return AuthenticationService.checkSystemAccessPermission(SYSTEM_NAME_ETD);
  }

  isIsbnAllowed() {
    return AuthenticationService.checkSystemAccessPermission(SYSTEM_NAME_ISBN);
  }

  isRoombookAllowed() {
    return AuthenticationService.checkSystemAccessPermission(SYSTEM_NAME_ROOMBOOK);
  }

  isZpAllowed() {
    return AuthenticationService.checkSystemAccessPermission(SYSTEM_NAME_ZP);
  }

  isUikAllowed() {
    return AuthenticationService.checkSystemAccessPermission(SYSTEM_NAME_UIK);
  }

  isHelpdeskAllowed() {
    return AuthenticationService.checkSystemAccessPermission(SYSTEM_NAME_HELPDESK);
  }

  isToolsAllowed() {
    return AuthenticationService.checkSystemAccessPermission(SYSTEM_NAME_TOOLS);
  }

  isLibadminAllowed() {
    return AuthenticationService.checkSystemAccessPermission(SYSTEM_NAME_LIBADMIN);
  }

  isAlfredAllowed() {
    return AuthenticationService.checkSystemAccessPermission(SYSTEM_NAME_ALFRED);
  }

  isHeliosAllowed() {
    return AuthenticationService.checkSystemAccessPermission(SYSTEM_NAME_HELIOS);
  }

  redirectToSystemByName(systemName: string) {
    switch (systemName) {
      case SYSTEM_NAME_ETD: this.redirectEtd(); break;
      case SYSTEM_NAME_UIK: this.redirectUik(); break;
      case SYSTEM_NAME_ZP: this.redirectZp(); break;
      case SYSTEM_NAME_ROOMBOOK: this.redirectRoombook(); break;
      case SYSTEM_NAME_LIBADMIN: this.redirectLibadmin(); break;
      case SYSTEM_NAME_ISBN: this.redirectIsbn(); break;
      case SYSTEM_NAME_EPC: this.redirectEpc(); break;
      case SYSTEM_NAME_HELPDESK: this.redirectHelpdesk(); break;
      case SYSTEM_NAME_ALFRED: this.redirectAlfred(); break;
      case SYSTEM_NAME_HELIOS: this.redirectHelios(); break;
    }
  }

  hasUserPermissionForSystemByName(systemName: string): boolean {
    switch (systemName) {
      case SYSTEM_NAME_ETD: return this.isEtdAllowed();
      case SYSTEM_NAME_UIK: return this.isUikAllowed();
      case SYSTEM_NAME_ZP: return this.isZpAllowed();
      case SYSTEM_NAME_ROOMBOOK: return this.isRoombookAllowed();
      case SYSTEM_NAME_LIBADMIN: return this.isLibadminAllowed();
      case SYSTEM_NAME_ISBN: return this.isIsbnAllowed();
      case SYSTEM_NAME_EPC: return this.isEpcAllowed();
      case SYSTEM_NAME_HELPDESK: return this.isHelpdeskAllowed();
      case SYSTEM_NAME_ALFRED: return this.isAlfredAllowed();
      case SYSTEM_NAME_HELIOS: return this.isHeliosAllowed();
      default: return false;
    }
  }
}
