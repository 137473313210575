import { Component, OnInit } from '@angular/core';
import { PriceListModel } from '../../models/priceList.model';
import { PriceListService } from '../../services/price-list.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-prices-view',
  templateUrl: './prices-view.component.html',
  styleUrls: ['./prices-view.component.scss'],
})
export class PricesViewComponent implements OnInit {
  prices: PriceListModel[] = [];
  loading = false;

  constructor(private priceListService: PriceListService, private toastr: ToastrService) {}

  ngOnInit() {
    this.getPriceList();
  }

  getPriceList() {
    this.loading = true;
    this.priceListService.getPriceList().subscribe(
      res => {
        this.prices = res;
        this.prices.sort((a, b) => {
          return a.code > b.code ? 1 : b.code > a.code ? -1 : 0;
        });
        this.loading = false;
      },
      error2 => {
        this.loading = false;
        console.log(error2);
      },
    );
  }

  onRowEditSave(price: PriceListModel) {
    this.loading = true;
    this.priceListService.updatePriceList(price.id, price.flatPrice, price.hourlyPrice, price.name).subscribe(
      () => {
        this.toastr.success('Miestnosť bola úspešne upravená', 'Upravené');
        this.getPriceList();
        this.loading = false;
      },
      e => {
        console.log(e);
        this.toastr.success(
          'Miestnosť nebola upravená. Prosím skontrolujte validnosť dát ako aj internetové pripojenie.',
          'Chyba',
        );
        this.loading = false;
      },
    );
  }
}
