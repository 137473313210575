import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BtnFilterItemModel } from './btn-filter-item.model';
import { COLORS } from '../../constants';

@Component({
  selector: 'app-btn-group-filter',
  templateUrl: './btn-group-filter.component.html',
  styleUrls: ['./btn-group-filter.component.scss'],
})
export class BtnGroupFilterComponent implements OnInit, OnDestroy {
  loaded = false;
  filterItems: BtnFilterItemModel[] = [];
  selectedItem: BtnFilterItemModel;

  @Output() onSelectEvent: EventEmitter<number> = new EventEmitter();
  @Input() allowAllFilterButton = false;
  @Input() selectedItemId: number;
  @Input() items: BtnFilterItemModel[];
  @Input() parameterToDisplay?: string = null;
  @Input() showCounts = false;
  @Input() countSettings: {bgColor: string, txtColor: string} = {bgColor: '', txtColor: ''};
  constructor() {
  }

  ngOnInit() {
    this.filterItems = this.items;

    if (this.allowAllFilterButton) {
      this.addAllItem();
      this.selectedItem = this.filterItems.filter(item => item.id === 0)[0];
      this.loaded = true;
    } else {
      this.selectedItem = this.filterItems[0];
      this.onSelectEvent.emit(this.selectedItem.id);
      this.loaded = true;
    }

    if (this.selectedItemId) {
      this.selectedItem = this.filterItems.filter(item => item.id === this.selectedItemId)[0];
    }

    this.onSelect(this.selectedItem);
  }

  ngOnDestroy() {
    this.filterItems = [];
    this.loaded = false;
    this.selectedItem = null;
  }

  addAllItem() {
    if (this.filterItems.filter(e => e.id === 0).length === 0) {
      this.filterItems
        .push({
          id: 0,
          title: 'all',
          color: COLORS.blueDark,
          count: 0,
          txtColor: COLORS.blueDark,
          brdColor: COLORS.blueDark,
          translated: 'Všetky',
        });
    }
  }

  onSelect(item) {
    this.selectedItem = item;
    this.onSelectEvent.emit(this.selectedItem.id);
  }

  getCounts(itemId): number | null {
    if (itemId === 0) {
      let sum = 0;
      this.filterItems.forEach(i => {
        if (i.count !== null) {
          sum += i.count;
        }
      });
      return sum;
    }
    return this.filterItems.filter(i => i.id === itemId)[0].count;
  }

}
