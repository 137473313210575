import { Pipe, PipeTransform } from '@angular/core';
import { AppCommunicationService } from "../../+app-custom/services/app-communication.service";

@Pipe({
  name: 'userLogin',
})
export class UserLoginPipe implements PipeTransform {
  static getCurrentUserLogin(): string {
    return AppCommunicationService.getCurrentUser().user.login;
  }

  transform(requestedLogin: string[]): any {
    const currentUserLogin = UserLoginPipe.getCurrentUserLogin();

    if (!requestedLogin || !currentUserLogin) {
      return;
    }

    return requestedLogin.some(login => currentUserLogin.includes(login));
  }
}
