import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserModel } from '../models/user.model';
import {
  getHistoricalUserByIdUrl,
  getSearchedUserUrl,
  getSearchUserCollateUrl,
  getUpdateExternalUserUrl,
  getUserPhotoUrl,
} from '../app.urls';
import { AppCommunicationService } from './app-communication.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core-frontend-common/auth/authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private router: Router, private authenticationService: AuthenticationService) {}

  getUserById(id: number): Observable<UserModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };

    if (this.authenticationService.checkUser()) {
      return this.http.post(getHistoricalUserByIdUrl(id), '', httpOptions).pipe(map(res => res as UserModel));
    }
  }

  getPhotoForUser(hash): Observable<Blob> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      responseType: 'blob' as 'blob',
    };
    if (this.authenticationService.checkUser()) {
      return this.http.get(getUserPhotoUrl(hash), httpOptions).pipe(map((res: any) => res.blob));
    }
  }

  getSearched(user): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.post(getSearchedUserUrl(), user, httpOptions);
    }
  }

  searchUser(phrase: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.get(getSearchUserCollateUrl(phrase), httpOptions);
    }
  }

  updateExternalThesisUser(userData): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.put(getUpdateExternalUserUrl(userData.id), userData, httpOptions);
    }
  }
}
