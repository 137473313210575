import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EventModel } from '../../models/event.model';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'app-delete-event-modal',
  templateUrl: './delete-event-modal.component.html',
  styleUrls: ['./delete-event-modal.component.scss'],
})
export class DeleteEventModalComponent implements OnInit {
  @Input() event: EventModel;
  @ViewChild('lgModal', { static: true }) deleteModal: ModalDirective;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private _eventService: EventService
  ) {}

  ngOnInit() {
  }

  show() {
    this.deleteModal.show();
  }

  /* ##### On DELETE button clicked ##### */
  onDeleteEvent() {
    this._eventService.deleteEvent(this.event.id).subscribe(
      () => {
        this.router.navigate(['/event']);
        this.deleteMessage();
      },
      error => console.log(error),
    );
  }

  deleteMessage() {
    this.toastr.info('Odstránené', 'Udalosť bola úspešne odstránená.');
  }
}
