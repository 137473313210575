import { Component, Input, OnInit } from '@angular/core';
import { NotificationModel } from "./notification.model";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  alertType: string;

  @Input() notification: NotificationModel;
  constructor() {}

  ngOnInit() {
    this.checkAlertType();
  }

  checkAlertType() {
    switch (this.notification.type) {
      case 'info':
        this.alertType = 'alert-info';
        break;
      case 'warn':
        this.alertType = 'alert-warning';
        break;
      case 'danger':
        this.alertType = 'alert-danger';
        break;
    }
  }
}
