import { NgModule } from '@angular/core';
import { UserRolePipe } from './user-role.pipe';
import { RoundPipe } from './round.pipe';

@NgModule({
  imports: [],
  declarations: [UserRolePipe, RoundPipe],
  exports: [UserRolePipe, RoundPipe],
  providers: [UserRolePipe, RoundPipe],
})
export class CustomPipesModule {}
