import { Pipe, PipeTransform } from '@angular/core';
import { ROOMS } from '../constants';

@Pipe({
  name: 'room',
})
export class RoomPipe implements PipeTransform {
  transform(roomName: any, returnType: string): any {
    if (roomName) {
      const filteredRoom = ROOMS.filter(room => room.acronym === roomName)[0];
      switch (returnType) {
        case 'acronym':
          return filteredRoom.acronym;
        case 'title':
          return filteredRoom.title;
        case 'color':
          return filteredRoom.color;
      }
    }
  }
}
