import { Component, OnInit } from '@angular/core';
import { AppVersionsService } from "../../../services/app-versions.service";
import { IAppVersion } from "../../../app-versions";

@Component({
  selector: 'smart-app-versions-notification',
  templateUrl: './app-versions-notification.component.html',
  styleUrls: ['./app-versions-notification.component.scss']
})
export class AppVersionsNotificationComponent implements OnInit {

  versions: IAppVersion[] = [];

  constructor(
    protected appVersionService: AppVersionsService
  ) { }

  ngOnInit() {
    this.loadVersions();
  }

  loadVersions() {
    this.versions = this.appVersionService.getVersions();

    this.appVersionService.storeToLocalStorage();
  }

}
