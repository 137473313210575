import { Injectable } from '@angular/core';
import { DEVAPI_BUILD, PRODUCTION_BUILD, SYSTEM_NAME } from '../../../../+app-custom/app.settings';
import { Observable } from 'rxjs';
import { config } from '../smartadmin.config';
import { HttpClient } from '@angular/common/http';
import { delay, map } from 'rxjs/operators';
import { SYSTEM_NAME_AUTH } from '../../../../+app-custom/constants';

@Injectable()
export class JsonApiService {
  constructor(private http: HttpClient) {}

  public fetch(url): Observable<any> {
    return this.http
      .get(this.getBaseUrl() + config.API_URL + url)
      .pipe(delay(100))
      .pipe(map(this.extractData));
  }

  private getBaseUrl(withoutSystemName = false) {
    // @ts-ignore
    if (SYSTEM_NAME === SYSTEM_NAME_AUTH) {
        return `${location.protocol}//${location.hostname}${
            location.port ? `:${location.port}` : ''
        }/`;
    }

    return PRODUCTION_BUILD || DEVAPI_BUILD
      ? `${location.protocol}//${location.hostname}${
          location.port ? `:${location.port}` : ''
        }/`
      : `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}/`;
  }

  private extractData(res: any) {
    if (res) {
      return res.data || res;
    } else {
      return {};
    }
  }

  private handleError(error: any) {
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
