import * as moment from 'moment';
import { OpenHourModel } from './models/open-hour.model';

export function calculateHoursRange(dates: any, times: any, openingHours: OpenHourModel[]): Promise<any> {
  let startDate = moment(dates.startDate);
  const endDate = moment(dates.endDate);
  const startTime = moment(times.startTime, 'H:mm', true);
  const endTime = moment(times.endTime, 'H:mm', true);

  const days = {
    basicDays: 0,
    friday: 0,
    weekDays: 0,
  };

  const basicDayHour = openingHours.find(o => o.dayOfWeek === 1);
  const fridayHour = openingHours.find(o => o.dayOfWeek === 5);

  const basicDay = {
    openHour: new Date(basicDayHour.startTime).getHours() || 7,
    closeHour: new Date(basicDayHour.endTime).getHours() || 18
  };

  const friday = {
    openHour: new Date(fridayHour.startTime).getHours() || 7,
    closeHour: new Date(fridayHour.endTime).getHours() || 15
  };

  while (startDate <= endDate) {
    if (startDate.day() >= 1 && startDate.day() <= 4) {
      days.basicDays++;
    } else if (startDate.day() === 5) {
      days.friday++;
    } else {
      days.weekDays++;
    }
    startDate = moment(startDate).add(1, 'day');
  }

  const numberOfAllDays = days.basicDays + days.friday + days.weekDays;

  const hoursCountRange =
    calculateBasicDays(days.basicDays, startTime, endTime, basicDay) +
    calculateFridays(days.friday, startTime, endTime, friday) +
    calculateWeekDays(days.weekDays, startTime, endTime);

  const hoursBetween = calculateNumberOfHours(numberOfAllDays, startTime, endTime);

  return Promise.resolve({
    daysCount: numberOfAllDays,
    hoursCountRange,
    hoursBetween,
  });
}

export function calculateNumberOfHours(numberOfAllDays, startTime, endTime) {
  // const endTimeMinutes = endTime.get('minutes');
  // const startTimeMinutes = startTime.get('minutes');
  //
  // if (endTimeMinutes > 0) {
  //   endTime.add(1, 'hour');
  // }
  //
  // return numberOfAllDays * endTime.diff(startTime, 'hours');

  const duration = moment.duration(endTime.diff(startTime));
  const hoursDifference = Math.ceil(duration.asHours());
  return hoursDifference * numberOfAllDays;

}

export function calculateBasicDays(basicDays, startTime, endTime, dayHours: {openHour: number, closeHour: number}) {
  let hoursOver = 0;
  console.log(startTime, endTime, dayHours)
  if (startTime.hour() >= dayHours.openHour && startTime.hour() <= dayHours.closeHour && endTime.hour() > dayHours.closeHour && endTime.hour() <= 24) {
    hoursOver = endTime.hour() - dayHours.closeHour;
  } else if (startTime.hour() >= dayHours.openHour && startTime.hour() <= dayHours.closeHour && endTime.hour() > 0 && endTime.hour() < dayHours.openHour) {
    hoursOver = endTime.hour() + (24 - dayHours.closeHour);
  }
  return basicDays * hoursOver;
}

export function calculateFridays(fridays, startTime, endTime, dayHours: {openHour: number, closeHour: number}) {
  let hoursOver = 0;
  if (startTime.hour() >= dayHours.openHour && startTime.hour() <= dayHours.closeHour && endTime.hour() > dayHours.closeHour && endTime.hour() <= 24) {
    hoursOver = endTime.hour() - dayHours.closeHour;
  } else if (startTime.hour() >= dayHours.openHour && startTime.hour() <= dayHours.closeHour && endTime.hour() > 0 && endTime.hour() < dayHours.openHour) {
    hoursOver = endTime.hour() + (24 - dayHours.closeHour);
  }
  return fridays * hoursOver;
}

export function calculateWeekDays(weekDays, startTime, endTime) {
  const diffHours = endTime.diff(startTime, 'hours') > 0 ? endTime.diff(startTime, 'hours') : 0;
  return weekDays * diffHours;
}

export function formatTimeToDatetime(time) {
  return moment(time, 'HH:mm').format('YYYY-MM-DD HH:mm:ss');
}

export function formatDateToDatetime(date) {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
}
