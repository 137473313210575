import { Injectable } from '@angular/core';
import { PriceListModel } from '../models/priceList.model';
import { getPriceListUrl, updatePriceListUrl } from '../app.urls';
import { AppCommunicationService } from './app-communication.service';
import { Router } from '@angular/router';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from '../../core-frontend-common/auth/authentication.service';

@Injectable()
export class PriceListService {
  checkUnauthorizedAccess(error) {
    if (error.status === 401) {
      this.router.navigate(['/login']);
      return observableThrowError('Unauthorized');
    }
    return observableThrowError('error');
  }

  constructor(private http: HttpClient, private router: Router, private authenticationService: AuthenticationService) {}

  getPriceList(): Observable<PriceListModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.get(getPriceListUrl(), httpOptions).pipe(
        map((res: any) => {
          return res.items as PriceListModel[];
        }),
        catchError(this.checkUnauthorizedAccess),
      );
    }
  }

  getRoomsPriceList(): Observable<PriceListModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.get(getPriceListUrl(), httpOptions).pipe(
        map((res: any) => {
          return res.items as PriceListModel[];
        }),
        catchError(this.checkUnauthorizedAccess),
      );
    }
  }

  updatePriceList(id, flatPrice, hourlyPrice, name): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http
        .put(
          updatePriceListUrl(id),
          {
            flatPrice,
            hourlyPrice,
            name,
          },
          httpOptions,
        )
        .pipe(catchError(this.checkUnauthorizedAccess));
    }
  }
}
