import { SYSTEM_FULL_NAME, SYSTEM_NAME } from './+app-custom/app.settings';

export const APP_CONFIG = {
  app: SYSTEM_NAME,
  appName: SYSTEM_FULL_NAME,
  user: 'Dr. Codex Lantern',
  email: 'drlantern@gotbootstrap.com',
  twitter: 'codexlantern',
  avatar: 'avatar-admin.png',
  version: '4.0.0',
  bs4v: '4.3',
  logo: 'logo.png',
  logoM: 'logo.png',
  copyright: 'UK Development Team © ',
};
