import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ReportBugModel } from './report-bug.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PRODUCTION_BUILD } from '../../../+app-custom/app.settings';
import { map } from 'rxjs/operators';
import { AppCommunicationService } from '../../../+app-custom/services/app-communication.service';

const enum methodEnum {
  create,
  get,
  solved,
  getById
}

@Injectable({
  providedIn: 'root'
})
export class ReportBugService {
  reportBug: Subject<boolean> = new Subject<boolean>();

  onClose: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected http: HttpClient
  ) { }

  send(data: ReportBugModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post(this.generateUrl(methodEnum.create), JSON.stringify(data), httpOptions);
  }

  getAll(filter: any): Observable<{ totalItems: number, items: ReportBugModel[] }> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post(this.generateUrl(methodEnum.get), filter, httpOptions)
      .pipe(map((r: any) => {
        return {
          totalItems: r.totalItems,
          items: r.items
        }
      }));
  }

  getById(id: number): Observable<ReportBugModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .get(this.generateUrl(methodEnum.getById, id), httpOptions)
      .pipe(map((r: any) => {
        return r as ReportBugModel;
      }));
  }

  setSolved(id: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post(this.generateUrl(methodEnum.solved) + `/${id}`, {}, httpOptions);
  }

  generateUrl(method: methodEnum, id: number = null): string {
    const isProd = PRODUCTION_BUILD;
    let baseUrl = '';

    if (isProd) {
      baseUrl += 'https://api.alfred.portal.tuke.sk/api/errorReport';
    } else {
      baseUrl += 'https://api.alfred.portal.dev.msweb.tuke.sk/api/errorReport';
    }

    switch (method) {
      case methodEnum.create: baseUrl += '/create'; break;
      case methodEnum.get: baseUrl += '/getAllByFilter'; break;
      case methodEnum.solved: baseUrl += '/setSolved'; break;
      case methodEnum.getById: baseUrl += '/get/' + id; break;
    }

    return baseUrl;
  }

}
