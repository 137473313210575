import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smart-page-heading',
  templateUrl: './page-heading.component.html',
  styles: [],
})
export class PageHeadingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
