import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap';
import { ReportBugService } from './report-bug.service';

@Component({
  selector: 'app-report-bug-dialog',
  templateUrl: './report-bug-dialog.component.html',
  styleUrls: ['./report-bug-dialog.component.scss']
})
export class ReportBugDialogComponent implements OnInit {
  public message = '';

  @ViewChild('staticModal', {static: false}) modal: ModalDirective;

  @Output() onReportBugSubmit: EventEmitter<string> = new EventEmitter<string>();
  constructor(
    protected reportBugService: ReportBugService
  ) { }

  ngOnInit() {
  }

  openModal() {
    this.modal.show();
  }

  close() {
    this.modal.hide();
    this.reportBugService.onClose.next(true);
  }

  clickedButton() {
    if (this.message && this.message.length > 0) {
      this.onReportBugSubmit.emit(this.message);
      this.message = '';
      this.close();
    }
    return;
  }
}
