import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReportBugService } from './+app-custom/components/report-bug-dialog/report-bug.service';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { I18nService } from './core-frontend-common/template/shared/i18n';
import { ToastrService } from 'ngx-toastr';
import * as html2canvas from 'html2canvas';
import { ReportBugDialogComponent } from './+app-custom/components/report-bug-dialog/report-bug-dialog.component';
import { SystemMaintanceModel } from './+app-custom/models/system-maintance.model';
import { AppThemeService } from './+app-custom/theme/app-theme.service';

@Component({
  selector: 'app-root',
  template: `
      <div class="alert alert-danger system-maintance-alert" *ngIf="isMaintanced">
          {{ systemMaintance.description }}
      </div>
      <router-outlet></router-outlet>
      <app-report-bug-dialog (onReportBugSubmit)="onReportBugDialogSubmit($event)"></app-report-bug-dialog>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  systemMaintance: SystemMaintanceModel = new SystemMaintanceModel();
  isMaintanced = false;

  reportBugSubscriber: Subscription;

  @ViewChild(ReportBugDialogComponent, { static: false }) reportBugDialog: ReportBugDialogComponent;

  constructor(
    private deviceService: DeviceDetectorService,
    protected reportBugService: ReportBugService,
    protected router: Router,
    protected i18n: I18nService,
    protected notification: ToastrService,
    private appThemeService: AppThemeService
  ) {}

  ngOnInit() {
    this.reportBugSubscriber = this.reportBugService.reportBug.subscribe(() => {
      this.reportBugDialog.openModal();
    });

    this.appThemeService.initTheme();
  }

  ngOnDestroy() {
    if (this.reportBugSubscriber) {
      this.reportBugSubscriber.unsubscribe();
    }
  }

  // checkSystemMaintance() {
  //     this._generalService
  //         .checkSystemMaintance()
  //         .subscribe(value => {
  //             this.isMaintanced = true;
  //         }, () => {
  //             this.isMaintanced = false;
  //         })
  // }

  onReportBugDialogSubmit(message: string) {
    if (message) {
      setTimeout(() => {
        this.clickedReportBug(message);
      }, 500);
    }
  }

  async clickedReportBug(message: string) {
    let deviceType = '';
    if (this.deviceService.isDesktop()) {
      deviceType = 'desktop';
    } else if (this.deviceService.isTablet()) {
      deviceType = 'tablet';
    } else if (this.deviceService.isMobile()) {
      deviceType = 'mobile';
    }

    const techData = {
      page: this.router.url.slice(0, 10),
      deviceInfo: this.deviceService.getDeviceInfo(),
      deviceType,
      screen: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };

    // @ts-ignore
    const canvas = await html2canvas(ignoreScreen ? document.getElementById('main') : document.body);
    if (canvas) {
      const image = canvas.toDataURL('image/jpeg');
      console.log('image: ', image);
      try {
        console.log('Report bug: ', message, JSON.stringify(techData));
      } catch (e) {
        this.notification.error('Nebolo možné odoslať hlásenie.', 'Chyba');
        console.log(e);
        return;
      }

      this.notification.success('Ďakujeme za spätnú väzbu. Vážime si to.', 'Hlásenie bolo odoslané.');
    }
  }
}
