import { Injectable } from "@angular/core";
import { SYSTEM_NAME } from "../../+app-custom/app.settings";
import { AppVersions, IAppVersion } from "../app-versions";

@Injectable({
  providedIn: 'root'
})
export class AppVersionsService {

  versions: IAppVersion[] = AppVersions;

  localStoragePropertyName = SYSTEM_NAME + '-appVersions';

  constructor() { }

  checkNotReadAppVersions(): boolean {

    return this.hasAnyVersionsMissing();

  }

  storeToLocalStorage() {

    const missing = this.hasAnyVersionsMissing();

    if (missing) {
      localStorage.removeItem(this.localStoragePropertyName);

      const v = [...this.versions];

      v.forEach(i => i.read = true);

      localStorage.setItem(this.localStoragePropertyName, JSON.stringify(v));
    }

  }

  getFromLocalStorage() {
    const stored = localStorage.getItem(this.localStoragePropertyName);

    if (stored) {
      return JSON.parse(stored) as IAppVersion[];
    }

    return null;
  }

  getVersions() {
    const missing = this.hasAnyVersionsMissing();

    let versions: IAppVersion[] = [];

    if (missing && this.versions.length > 0) {
      versions = [...this.versions];
    } else {
      const stored = this.getFromLocalStorage();

      if (stored) {
        versions = [...stored];
      }
    }

    if (versions && versions.length > 0) {
      return versions.sort((a, b) => {
        return b.version - a.version;
      });
    }

    return [];
  }

  hasAnyVersionsMissing(): boolean {
    const versions = this.getFromLocalStorage();

    if (versions) {
      const hasAnyMissing = this.versions.filter(obj => versions.every(s => s.version !== obj.version));

      return hasAnyMissing && hasAnyMissing.length > 0;
    }

    return !versions && this.versions.length > 0;

  }
}
