export const AppVersions: IAppVersion[] = [
  {
    version: 1.1,
    date: new Date('09/02/2021'),
    value: {
      title: 'Nová verzia systému',
      items: [
        'Filter tiketov',
        'Zobrazenie tlačidiel tiketu',
        'Pridanie validátorov do formulára publikácií',
        'Vylepšenie responzivity',
        '... dalšie drobné vylepšenia'
      ]
    },
    read: false
  },
  {
    version: 1.3,
    date: new Date('09/28/2021'),
    value: {
      title: 'Nová verzia systému',
      items: [
        'Citácie',
        'Registrácia citácií',
        'Upravená sekcia "Moje publikácie" so zaimplementovanými citáciami',
        'Upravená sekcia "Katalóg tiketov" so zaimplementovanými citáciami',
        'Upravený filter v sekcii "Katalóg tiketov" - pridaná fakulta a typ (biblio/citácia)',
        'Upravené dáta pre subsekciu "Všetky záznamy" - zobrazené tikety na príjme',
        'Vylepšenia formulára registrácie publikácie',
        'Vylepšenie responzivity',
        '... dalšie drobné vylepšenia'
      ]
    },
    read: false
  },
  {
    version: 1.4,
    date: new Date('09/29/2021'),
    value: {
      title: 'Nová verzia systému',
      items: [
        'Citácie',
        'Narábanie so stavmi citácií - nakonfigurovaný celý flow v rámci ticketu',
        'Pridané nové tlačidlá ticketu - zobraziť biblio a importovať z DW',
        'Pridané nové funkcie pre import z DW',
        'Nakonfigurovaný biblio detail v sekcii DW',
        'Upravené dáta',
        '... dalšie drobné vylepšenia'
      ]
    },
    read: false
  },
  {
    version: 1.5,
    date: new Date('09/30/2021'),
    value: {
      title: 'Nová verzia systému',
      items: [
        'Biblio',
        'Narábanie s biblio záznamami',
        'Nakonfigurovaný náhľad biblio záznamu',
        'Možnosť odoslať tiket na finálny stav',
        'Nahlásenie chyby ako položka v menu - dočasne pri spustení systému',
        'Zaimplementovaná možnosť zobraziť citáciu pre daný biblio záznam',
        'Upravená sekcia "Moje publikácie"',
        '... dalšie drobné vylepšenia'
      ]
    },
    read: false
  }
];

export interface IAppVersion {
  version: number;
  date: Date;
  value: {
    title: string;
    items: string[];
  };
  read: boolean;
}

