import { NgModule } from "@angular/core";
import { AppThemeService } from "./app-theme.service";

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    AppThemeService
  ],
  exports: []
})

export class AppThemeModule { }
