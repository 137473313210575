import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AppVersionsService } from "../../services/app-versions.service";

@Component({
  selector: 'smart-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  modalRef: BsModalRef;

  notificationsCount = 2;

  hasNotReadAppVersion = false;

  @ViewChild('template', { static: true }) template: any;

  constructor(
    private modalService: BsModalService,
    protected appVersions: AppVersionsService
  ) {
  }

  ngOnInit() {
    this.checkAppVersions();
  }

  openModal($event: MouseEvent) {
    $event.preventDefault();
    this.showModal();
  }

  closeModal() {
    this.checkAppVersions();
    this.modalRef.hide();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-right modal-md',
    });
  }

  ngOnDestroy(): void {
  }

  checkAppVersions() {
    this.hasNotReadAppVersion = this.appVersions.checkNotReadAppVersions();
  }
}
