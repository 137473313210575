import { Directive, ElementRef, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
  selector: '[ngModel][floatNumberFormat]',
})

// Direktiva na vstupe hlada desatinne cislo, ak obsahuje ciarku, konvertuje ju automaticky na bodku
export class FloatNumberFormatDirective {
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  value: any;

  @HostListener('input', ['$event']) onInputChange($event) {
    this.value = $event.target.value.replace(',', '.');
    this.ngModelChange.emit(this.value);
  }
}
