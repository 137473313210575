import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-float-price-table',
  templateUrl: './float-price-table.component.html',
  styleUrls: ['./float-price-table.component.scss'],
})
export class FloatPriceTableComponent implements OnInit {
  pricing = false;

  constructor() {}

  ngOnInit() {}

  togglePricing(): void {
    this.pricing = !this.pricing;
  }
}
