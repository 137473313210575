import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class SharedDateService {
  months = [
    'január',
    'február',
    'marec',
    'apríl',
    'máj',
    'jún',
    'júl',
    'august',
    'september',
    'október',
    'november',
    'december',
  ];
  activeDate = [0, 0];
  year = 0;
  month = 0;

  constructor() {
    this.deselectActiveDate();
  }

  /* ##### Set active date for calendar && month select in events view to share date ##### */
  createDateNow() {
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
    this.setDate();
  }

  previousMonth() {
    if (this.month > 0) {
      this.month--;
    } else {
      this.month = 11;
      this.previousYear();
    }
    this.setDate();
  }

  nextMonth() {
    if (this.month < 11) {
      this.month++;
    } else {
      this.month = 0;
      this.nextYear();
    }
    this.setDate();
  }

  previousYear() {
    this.year--;
  }

  nextYear() {
    this.year++;
  }

  setDate(): void {
    this.activeDate[0] = this.month;
    this.activeDate[1] = this.year;
  }

  setDateByWeek(dateInWeek) {
    this.activeDate[0] = moment(dateInWeek).month();
    this.activeDate[1] = moment(dateInWeek).year();
  }

  deselectActiveDate() {
    this.createDateNow();
  }

  getMonthByIndex(index: number): string {
    return this.months[index];
  }
}
