import { Injectable } from '@angular/core';
import { getRoomsUrl } from '../app.urls';
import { AppCommunicationService } from './app-communication.service';
import { RoomModel } from '../models/room.model';
import { PriceListService } from './price-list.service';
import { Router } from '@angular/router';
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, flatMap, map } from 'rxjs/operators';
import { PriceListModel } from '../models/priceList.model';
import { AuthenticationService } from '../../core-frontend-common/auth/authentication.service';

@Injectable()
export class RoomService {
  checkUnauthorizedAccess(error) {
    if (error.status === 401) {
      this.router.navigate(['/login']);
      return observableThrowError('Unauthorized');
    }
    return observableThrowError('error');
  }

  constructor(
    private http: HttpClient,
    private appCommunicationService: AppCommunicationService,
    private priceListService: PriceListService,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  getRooms(): Observable<RoomModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };
    if (this.authenticationService.checkUser()) {
      let roomsResult = [];

      return this.http
        .get(getRoomsUrl(), httpOptions)
        .pipe(
          flatMap((rooms: any) => {
            roomsResult = rooms.items;
            return this.priceListService.getRoomsPriceList();
          }),
        )
        .pipe(
          flatMap((priceList: PriceListModel[]) => {
            return this.mapRooms(priceList, roomsResult);
          }),
        );
    }
  }

  mapRooms(priceList: PriceListModel[], rooms: RoomModel[]): Promise<RoomModel[]> {
    // const roomsModels = [
    //   {
    //     name: '1',
    //     acronym: 'bigConfRoom',
    //     color: '#92a2a8',
    //   },
    //   {
    //     name: '2',
    //     acronym: 'smallConfRoom',
    //     color: '#356e35',
    //   },
    //   {
    //     name: '3',
    //     acronym: 'expHall',
    //     color: '#6e3671',
    //   },
    //   {
    //     name: '4',
    //     acronym: 'meet6floor',
    //     color: '#C02631',
    //   },
    // ];
    const colors = [
      '#92a2a8',
      '#356e35',
      '#6e3671',
      '#C02631',
      '#DCE775',
      '#A1887F',
      '#90A4AE',
      '#81C784',
      '#4DB6AC',
      '#4DD0E1',
      '#9575CD',
    ];

    const mappedRooms: RoomModel[] = [];
    if (rooms !== undefined) {
      let index = 0;
      rooms.forEach(room => {
        room.flatPrice = priceList.filter(obj => obj.code === room.code).map(obj => obj.flatPrice)[0];
        room.hourlyPrice = priceList.filter(obj => obj.code === room.code).map(obj => obj.hourlyPrice)[0];
        // room.code = roomsModels.filter(obj => obj.name === room.code).map(obj => obj.acronym)[0];
        room.color = colors[index];
        mappedRooms.push(room);
        index++;
      });
    }
    return Promise.resolve(mappedRooms);
  }
}
