import { Injectable } from '@angular/core';
import { deleteEventUrl, getEventsUrl, postEventUrl, updateEventUrl } from '../app.urls';
import { EventModel } from '../models/event.model';
import { AppCommunicationService } from './app-communication.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from '../../core-frontend-common/auth/authentication.service';
import { PRODUCTION_BUILD } from '../app.settings';

@Injectable()
export class EventService {
  checkUnauthorizedAccess(error) {
    if (error.status === 401) {
      this.router.navigate(['/login']);
      return observableThrowError('Unauthorized');
    }
    return observableThrowError('error');
  }

  constructor(
    private http: HttpClient,
    private appCommunicationService: AppCommunicationService,
    private userService: UserService,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  getEvents(monthInYear, mapUsers?: boolean): Observable<EventModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };

    if (this.authenticationService.checkUser()) {
      return this.http.get(getEventsUrl(monthInYear), httpOptions).pipe(
        map((res: any) => {
          return this.mapEvents(res.items as EventModel[]);
        }),
        catchError(this.checkUnauthorizedAccess),
      );
    }
  }

  postEvent(event: EventModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.post(postEventUrl(), event, httpOptions).pipe(catchError(this.checkUnauthorizedAccess));
    }
  }

  updateEvent(event: EventModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.put(updateEventUrl(event.id), event, httpOptions).pipe(catchError(this.checkUnauthorizedAccess));
    }
  }

  deleteEvent(id: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.put(deleteEventUrl(id), {}, httpOptions).pipe(catchError(this.checkUnauthorizedAccess));
    }
  }

  mapEvents(events: EventModel[]): EventModel[] {
    const roomsModels = [
      {
        name: '1',
        acronym: 'VKM',
        color: '#92a2a8',
      },
      {
        name: PRODUCTION_BUILD ? '2' : '5',
        acronym: 'MKM',
        color: '#356e35',
      },
      {
        name: '3',
        acronym: 'VS',
        color: '#6e3671',
      },
      {
        name: '8',
        acronym: 'Z6P',
        color: '#C02631',
      },
      {
        name: '7',
        acronym: 'S2P',
        color: '#DCE775',
      },
      {
        name: '6',
        acronym: 'S4P',
        color: '#A1887F',
      },
      {
        name: '9',
        acronym: 'S6P',
        color: '#90A4AE',
      },
    ];
    const mappedEvents: EventModel[] = [];
    if (events !== null) {
      for (let i = 0; i < events.length; i++) {
        if (events[i].rooms !== null) {
          const mappedEvent = events[i];
          for (let j = 0; j < events[i].rooms.length; j++) {
            roomsModels.filter(obj => {
              if (obj.name === mappedEvent.rooms[j].code) {
                mappedEvent.rooms[j].codeText = obj.acronym;
                mappedEvent.rooms[j].color = obj.color;
              }
            });
          }
          mappedEvents.push(mappedEvent);
        }
      }
    }
    return mappedEvents;
  }
}
