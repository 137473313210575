export class ParticipantModel {
  createdById: number;
  createdDatetime: string;
  email: string;
  id: number;
  isExternal: boolean;
  licenseFileId: number;
  login: string;
  name: string;
  reviewFileId: number;
  reviewId: number;
  role: number;
  surname: string;
  title: string;
  titleAfter: string;
  updatedById: number;
  updatedDatetime: string;
  userId: number;
  workCenterName: string;
  workCenterId: number;
  facultyId: number;
  facultyName: string;
  phone?: string;
  orderNumber?: number;
}
