import { Component, Input, OnInit } from "@angular/core";
import { TileModel } from "./tile.model";

@Component({
  selector: "app-tile",
  templateUrl: "./tile.component.html",
  styleUrls: ["./tile.component.scss"]
})
export class TileComponent implements OnInit {

  @Input() tile: TileModel;

  constructor() {
  }

  ngOnInit() {
  }
}
