import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPLOYEE_OVERTIME_HOUR_PRICE, ROOM_DISCOUNTS, skLocalizationCalendar } from '../../constants';
import { EventModel } from '../../models/event.model';
import { RoomModel } from '../../models/room.model';
import { OfferedServiceModel } from '../../models/offeredservice.model';
import { DeleteEventModalComponent } from '../../components/delete-event-modal/delete-event-modal.component';
import { AppCommunicationService } from '../../services/app-communication.service';
import { EventSharedService } from '../../services/event-shared.service';
import { OfferedServicesService } from '../../services/offered-services.service';
import { RoomService } from '../../services/room.service';
import { EventService } from '../../services/event.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { first, flatMap } from 'rxjs/operators';
import { calculateHoursRange } from '../../helper-functions';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-new-event-view',
  templateUrl: './new-event-view.component.html',
  styleUrls: ['./new-event-view.component.scss'],
})
export class NewEventViewComponent implements OnInit {
  employeeHourPrice = EMPLOYEE_OVERTIME_HOUR_PRICE;
  loading = true;
  isInEditMode = false;
  newEventForm: FormGroup;
  skLocalizationCalendar = skLocalizationCalendar;
  discounts = ROOM_DISCOUNTS;
  selectedEvent: EventModel;
  price = 0;
  /* ##### Employee overtime variables ##### */
  employeeOvertime = {
    count: 0,
    visible: false,
    hoursOver: 0,
    price: 0,
  };
  calculatedPrices = {
    rooms: 0,
    services: 0,
    discounts: 0,
    calculatedPrice: 0,
    dealedPrice: 0,
  };
  roomCheckboxesValid = false;
  rooms: RoomModel[] = [];
  offeredServices: OfferedServiceModel[] = [];

  checkedRooms: RoomModel[] = [];
  checkedOfferedServices: OfferedServiceModel[] = [];
  newEvent: EventModel = new EventModel();
  /* ##### When editing, dealed price in model is setted to zero and original value is showed, stored in this variable ##### */
  originallyDealedPrice = 0;
  resendEmailNotification = false;
  eventsInConflict: EventModel[] = [];

  openHours: any[] = [];

  @ViewChild(DeleteEventModalComponent, { static: true }) deleteEventModalComponent: DeleteEventModalComponent;

  constructor(
    private formBuilder: FormBuilder,
    private _appCommunicationService: AppCommunicationService,
    private _eventSharedService: EventSharedService,
    private elRef: ElementRef,
    private _offeredServicesService: OfferedServicesService,
    private _roomService: RoomService,
    private _eventService: EventService,
    private router: Router,
    private toastr: ToastrService,
    private generalService: GeneralService
  ) {
  }

  ngOnInit() {
    this.isInEditMode = this._eventSharedService.isSelectedEvent;

    if (this.isInEditMode) {
      this.selectedEvent = this._eventSharedService.selectedEvent;
    }

    this.generalService.getOpenHours()
      .pipe(first())
      .subscribe({
        next: value => {
          this.openHours = value;
        }
      });

    this._offeredServicesService
      .getOfferedServices()
      .pipe(
        flatMap(services => {
          if (services) {
            services.forEach(service => (service.checked = false));
            const employeePrice = services.find(s => s.code.toLowerCase() === 'staff');
            if (employeePrice) {
              this.employeeHourPrice = employeePrice.hourlyPrice;
            }
            this.offeredServices = services;
          }
          return this._roomService.getRooms();
        }),
      )
      .pipe(
        flatMap(data => {
          if (data) {
            data.forEach(room => (room.checked = false));
            this.rooms = data;
          }

          return this.initializeNewEventForm();
        }),
      )
      .pipe(flatMap(() => this.fullFillDiscounts()))
      .subscribe(() => {
        if (this.isInEditMode) {
          this.onEditFullFillData();
        } else if (this._eventSharedService.isSelectedEventDate) {
          this.newEventForm
            .get('startDate')
            .patchValue(moment(this._eventSharedService.selectedEventDate).format('YYYY-MM-DD'));
        }
        this.loading = false;
      });

    this.newEvent.rooms = [];
    this.newEvent.offeredServices = [];
  }

  onEditFullFillData() {
    this.selectedEvent = this._eventSharedService.selectedEvent;
    this.selectedEvent.startTime = moment(this.selectedEvent.startTime).format('HH:mm');
    this.selectedEvent.endTime = moment(this.selectedEvent.endTime).format('HH:mm');
    this.originallyDealedPrice = this.selectedEvent.dealedPrice;
    this.fullFillNewEventForm();
    this.checkCheckedRooms();
    this.checkCheckedServices();
    this.checkCheckedDiscount();

    this.newEventForm.get('dealedPrice').patchValue('');
  }

  /* ##### Check checkbox of checked rooms when editing ##### */
  checkCheckedRooms() {
    this.calculatedPrices.rooms = 0;
    this.checkedRooms = [];
    this.selectedEvent.rooms.forEach(room => {
      const res = this.rooms.filter(r => r.id === room.id)[0];
      this.checkedRooms.push(res);
      this.newEvent.rooms.push(res.id);
      res.checked = true;
      this.roomCheckboxesValid = true;
    });
    this.recalculatePrice();
  }

  /* ##### Check checkbox of checked services when editing ##### */
  checkCheckedServices() {
    this.calculatedPrices.services = 0;
    this.checkedOfferedServices = [];
    this.selectedEvent.offeredServices.forEach(offeredService => {
      const res = this.offeredServices.filter(service => service.id === offeredService.id)[0];
      this.newEvent.offeredServices.push(res.id);
      res.checked = true;
      if (res.code.toLowerCase() === 'staff') {
        this.employeeOvertime.visible = true;
        this.employeeOvertime.count = this.selectedEvent.employeeCount;
        this.calculateEmployeePrice(this.employeeOvertime.count);
      }
      this.checkedOfferedServices.push(res);
    });
    this.recalculatePrice();
  }

  /* ##### Check radio button of checked discount when editing ##### */
  checkCheckedDiscount() {
    this.calculatedPrices.discounts = 0;
    this.discounts.forEach(discount => {
      if (discount.value === this.selectedEvent.discount) {
        discount.checked = true;
        this.calculatedPrices.discounts = discount.value;
      }
    });
    this.recalculatePrice();
  }

  /* ##### Fullfill discounts from constants and add 'checked' attribute for checkbox ##### */
  fullFillDiscounts(): Observable<any> {
    this.discounts.forEach(discount => (discount.checked = false));
    this.discounts[0].checked = true;
    return of(this.discounts);
  }

  /* ##### Triggered function when time is changed ##### */
  onTimeInputChanges(value, formValue: string) {
    this.newEventForm.get(formValue).patchValue(value);
    this.recalculatePrice();
  }

  /* ##### Triggered function when room is checked/unchecked, price is then recalculated automatically ##### */
  onRoomChange(value, room: RoomModel) {
    if (value.target.checked) {
      this.checkedRooms.push(room);
    } else {
      const index = this.checkedRooms.findIndex(s => s.id === room.id);
      this.checkedRooms.splice(index, 1);
    }
    this.roomCheckboxesValid = this.checkedRooms.length > 0;
    this.recalculatePrice();
  }

  /* ##### Triggered function when service is checked/unchecked, price is then recalculated automatically ##### */
  onServicesChange(value, service: OfferedServiceModel) {
    if (value.target.checked) {
      this.checkedOfferedServices.push(service);
    } else {
      const index = this.checkedOfferedServices.findIndex(s => s.id === service.id);
      this.checkedOfferedServices.splice(index, 1);
    }

    // check staff checked
    const withStaff = this.checkedOfferedServices.find(s => s.code.toLowerCase() === 'staff');
    if (withStaff) {
      this.employeeOvertime.visible = true;
    } else {
      this.employeeOvertime.visible = false;
    }

    this.recalculatePrice();
  }

  /* ##### Triggered function when discount changed, price is then recalculated automatically ##### */
  onDiscountsChange(value, discount) {
    discount.checked = value;
    this.calculatedPrices.discounts = Number.parseInt(value, 0);
    this.newEventForm.get('discount').patchValue(Number.parseInt(value, 0));
    this.recalculatePrice();
  }

  /* ##### Price recalculation based on dates, times, rooms, services and discounts ##### */
  recalculatePrice() {
    if (
      this.newEventForm.get('startDate').value &&
      this.newEventForm.get('endDate').value &&
      this.newEventForm.get('startTime').value &&
      this.newEventForm.get('endTime').value
    ) {
      calculateHoursRange(
        {
          startDate: this.newEventForm.get('startDate').value,
          endDate: this.newEventForm.get('endDate').value,
        },
        {
          startTime: this.newEventForm.get('startTime').value,
          endTime: this.newEventForm.get('endTime').value,
        },
        this.openHours
      ).then(res => {

        this.employeeOvertime.hoursOver = res.hoursCountRange;

        if (this.employeeOvertime.visible) {
          this.calculateEmployeePrice();
        } else {
          this.employeeOvertime.price = 0;
        }

        this.calculatedPrices.calculatedPrice = 0;

        this.calculatedPrices.services = 0;

        this.checkedOfferedServices.forEach(s => this.calculatedPrices.services += s.flatPrice);

        if (res.hoursBetween > 0) {

          this.checkedRooms.forEach(room => {
            this.calculatedPrices.calculatedPrice += ((room.flatPrice * res.daysCount) + room.hourlyPrice * res.hoursBetween) * (1 - this.calculatedPrices.discounts / 100);
          });

        } else {

          this.checkedRooms.forEach(room => {
            this.calculatedPrices.calculatedPrice += ((room.flatPrice * res.daysCount) + room.hourlyPrice) * (1 - this.calculatedPrices.discounts / 100);
          });

        }

        this.calculatedPrices.calculatedPrice += this.employeeOvertime.price + this.calculatedPrices.services;

        this.newEventForm.get('catalogPrice').patchValue(this.calculatedPrices.calculatedPrice);
      });
    } else {
      this.newEventForm.get('catalogPrice').patchValue(0);
    }
  }

  // getAllEventsInSpecificDates(date1, date2) {
  //   this._eventService
  //     .getEvents(moment(date1).get('month'), false)
  //     .subscribe(val => {
  //       this.eventsInConflict = val
  //         .filter(event => {
  //           return moment(date1)
  //               .isBetween(moment(event.startDate, 'YYYY-MM-DD HH:mm:ss'),
  //                 moment(event.endDate, 'YYYY-MM-DD HH:mm:ss'), null, '[]') ||
  //             moment(date2).isBetween(moment(event.startDate, 'YYYY-MM-DD HH:mm:ss'),
  //               moment(event.endDate, 'YYYY-MM-DD HH:mm:ss'), null, '[]');
  //         });
  //     });
  // }

  /* ##### New event form initialization ##### */
  initializeNewEventForm(): Observable<any> {
    this.newEventForm = this.formBuilder.group({
      catalogPrice: new FormControl({ value: 0, disabled: true }),
      comment: [''],
      countPeople: [0],
      dealedPrice: ['', Validators.required],
      endDate: ['', Validators.required],
      name: ['', Validators.required],
      paymentType: 1,
      responsiblePersonName: ['', Validators.required],
      startDate: ['', Validators.required],
      endTime: ['', Validators.required],
      startTime: ['', Validators.required],
      discount: [0],
      employeeCount: [0],
      sendEmailNotification: [false],
      email: [''],
    });
    return of(this.newEventForm.value);
  }

  /* ##### Fullfill data in new event form when editing ##### */
  fullFillNewEventForm(): Observable<any> {
    this.newEventForm.get('catalogPrice').patchValue(this.selectedEvent.catalogPrice);
    this.newEventForm.get('comment').patchValue(this.selectedEvent.comment);
    this.newEventForm.get('countPeople').patchValue(this.selectedEvent.countPeople);
    this.newEventForm.get('dealedPrice').patchValue(this.selectedEvent.dealedPrice);
    this.newEventForm.get('endDate').patchValue(new Date(this.selectedEvent.endDate));
    this.newEventForm.get('name').patchValue(this.selectedEvent.name);
    this.newEventForm.get('responsiblePersonName').patchValue(this.selectedEvent.responsiblePersonName);
    this.newEventForm.get('startDate').patchValue(new Date(this.selectedEvent.startDate));
    this.newEventForm.get('startTime').patchValue(this.selectedEvent.startTime);
    this.newEventForm.get('endTime').patchValue(this.selectedEvent.endTime);
    this.newEventForm.get('discount').patchValue(this.selectedEvent.discount);
    this.newEventForm.get('employeeCount').patchValue(this.selectedEvent.employeeCount);
    this.newEventForm.get('email').patchValue(this.selectedEvent.email);
    return of(this.newEventForm.value);
  }

  calculateEmployeePrice(value = null) {
    this.employeeOvertime.price = this.employeeOvertime.hoursOver * EMPLOYEE_OVERTIME_HOUR_PRICE * this.employeeOvertime.count;
  }

  /* ##### On submit function ##### */
  onSubmit() {
    const temporaryEvent = this.newEvent;
    this.newEvent = this.newEventForm.value;
    this.newEvent.rooms = this.checkedRooms.map(room => {
      return {
        roomId: room.id,
        discount: this.calculatedPrices.discounts
      };
    });
    this.newEvent.offeredServices = this.checkedOfferedServices.map(service => {
      return {
        offeredServiceId: service.id,
        discount: this.calculatedPrices.discounts
      };
    });
    this.newEvent.catalogPrice = this.newEventForm.get('catalogPrice').value;
    this.newEvent.startDate = moment(this.newEvent.startDate).format('YYYY-MM-DDTHH:mm:ss');
    this.newEvent.endDate = moment(this.newEvent.endDate).format('YYYY-MM-DDTHH:mm:ss');
    this.loading = true;
    this._appCommunicationService.loadingOn();
    if (this.isInEditMode) {
      this.newEvent.id = this.selectedEvent.id;
      this._eventService.updateEvent(this.newEvent).subscribe(
        () => {
          this.router.navigate(['/event']);
          this.successMessage();
          this.loading = false;
          this._appCommunicationService.loadingOff();
        },
        error => {
          this.errorMessage();
          this.loading = false;
          this._appCommunicationService.loadingOff();
          console.log(error);
        },
      );
    } else {
      this._eventService.postEvent(this.newEvent).subscribe(
        () => {
          this.router.navigate(['/event']);
          this.successMessage();
          this._appCommunicationService.loadingOff();
          this.loading = false;
        },
        error2 => {
          this.errorMessage();
          this._appCommunicationService.loadingOff();
          this.loading = false;
          console.log(error2);
        },
      );
    }
  }

  sendEmailNotificationChange() {
    this.resendEmailNotification = !this.resendEmailNotification;
    this.newEventForm.patchValue({
      sendEmailNotification: this.resendEmailNotification,
    });
  }

  /* ##### On BACK button clicked ##### */
  onBackClicked() {
    this._eventSharedService.deselectEvent();
    this.router.navigate(['/event']);
  }

  /* ##### On DELETE button clicked ##### */
  onDeleteClicked() {
    this.deleteEventModalComponent.show();
  }

  /* ##### Alert messages ##### */
  successMessage() {
    if (this.isInEditMode) {
      this.toastr.success('Udalosť bola úspešne upravená.', 'Upravené');
    } else {
      this.toastr.success('Udalosť bola úspešne vytvorená.', 'Vytvorené');
    }
  }

  errorMessage() {
    if (this.isInEditMode) {
      this.toastr.error('Udalosť nebola upravená. Skontrolujte prosím správnosť údajov.', 'Chyba');
    } else {
      this.toastr.error('Udalosť nebola vytvorená. Skontrolujte prosím správnosť údajov.', 'Chyba');
    }
  }
}
