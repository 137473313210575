export class ExternalUserModel {
  id?: number;
  login = '';
  name = '';
  surname = '';
  title = '';
  titleAfter = '';
  activeStatusCode = '';
  personTypeCode = '';
  employmentCode = '';
  birthdate = '';
  email = '';
  phone = '';
  workCenterName = '';
  street = '';
  postalCode = '';
  city = '';
  country = '';
}
