import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CURRENT_API_HOST_URL, getSessionValidityCheckUrl } from 'src/app/+app-custom/app.urls';
import { AppRedirectService } from '../app-redirect.service';
import { getLoginUrl, getLogoutUrl } from '../auth.urls';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private calledLogout = false;
  private tokenRefreshedSubject: Subject<any> = new Subject<any>();
  private tokenRefreshed: Observable<any> = this.tokenRefreshedSubject.asObservable();

  constructor(private injector: Injector) {
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // Ak bude potrebne pouzit v interceptore nejaku service, tak referenciu tahat cez Injector, nie cez DI konstruktora!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authenticationService: AuthenticationService = this.injector.get(AuthenticationService);
    const appRedirectService: AppRedirectService = this.injector.get(AppRedirectService);

    // TODO: Sem moze prist logika napriklad na automaticke doplnenie Content-Type headera, vlozenie Bearer tokenu a podobne...

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        // TODO: Sem moze prist logika na handling response ak pride stavovy kod HTTP triedy OK (2xx)
      }),
      catchError(error => {
        if (
          !(error instanceof HttpErrorResponse) ||
          !_.startsWith((error as HttpErrorResponse).url, CURRENT_API_HOST_URL)
        ) {
          return throwError(error.error);
        }

        if (_.startsWith(error.url, getLoginUrl())) {
          if (error.status !== 400) {
            // TODO: Zobrazit error toast ak je potrebne
          } else {
            this.logout(error, authenticationService, appRedirectService);
          }

          return throwError(error.error);
        } else if (_.startsWith(error.url, getLogoutUrl())) {
          if (error.status !== 401) {
            // TODO: Zobrazit error toast ak je potrebne
          }

          return throwError(error.error);
        } else if (_.startsWith(error.url, getSessionValidityCheckUrl())) {
            if (error.status !== 401) {
                // TODO: Zobrazit error toast ak je potrebne
            } else {
                this.logout(error, authenticationService, appRedirectService);
            }

            return throwError(error.error);
        } else if (error.status === 401) {
          if (AuthenticationService.isSessionExpired()) {
            // TODO: Zobrazit error toast ze nastala chyba pri odhlasovani ak je potrebne

            this.logout(error, authenticationService, appRedirectService);
          } else if (
            !AuthenticationService.checkExpirationTime() &&
            AuthenticationService.checkRefreshExpirationTime()
          ) {
            return this.refreshToken(authenticationService).pipe(
              switchMap(() => {
                return next.handle(req);
              }),
              catchError(refreshError => {
                // TODO: Zobrazit error toast ze nastala chyba autentifikacie ak je potrebne

                if (refreshError.status === 400 || refreshError.status === 401 || refreshError.status === 500) {
                  this.logout(refreshError, authenticationService, appRedirectService);

                  return EMPTY;
                }

                return throwError(error.error);
              }),
            );
          } else {
            // TODO: Zobrazit error toast 401 ak je potrebne

            this.logout(error, authenticationService, appRedirectService);
          }
        } else if (error.status < 200 || error.status > 299) {
          // TODO: Zobrazit error toast ak je potrebne ked sa vyskytne neocakavany stav alebo chyba protokolu
        }

        return throwError(error);
      }),
    );
  }

  private logout(
    response: HttpResponse<any> | HttpErrorResponse,
    authenticationService: AuthenticationService,
    appRedirectService: AppRedirectService,
  ): void {
    if (this.calledLogout) {
      return;
    }

    this.calledLogout = true;
    authenticationService.logout().subscribe({
      next: () => {
        this.calledLogout = false;
      },
      error: error => {
        this.calledLogout = false;
      },
    });
  }

  private refreshToken(authenticationService: AuthenticationService): Observable<any> {
    if (authenticationService.tokenRefreshInProgress) {
      return new Observable(subscriber => {
        this.tokenRefreshed.subscribe({
          next: () => {
            subscriber.next();
            subscriber.complete();
          },
          error: error => {
            subscriber.error(error);
            subscriber.complete();
          },
        });
      });
    } else {
      const currentSession = AuthenticationService.getCurrentUser();

      if (_.isNil(currentSession)) {
        return throwError('No session present!');
      }

      return authenticationService
        .getNewToken(currentSession.user.login, currentSession.refresh_token, currentSession.session_guid)
        .pipe(
          tap({
            next: () => {
              this.tokenRefreshedSubject.next();
            },
            error: error => {
              // console.log(error);
              this.tokenRefreshedSubject.error(error);
            },
          }),
        );
    }
  }
}
