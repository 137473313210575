import { SYSTEM_NAME_AUTH, UserRoles } from './+app-custom/constants';
import { getBaseFrontendUrl } from './+app-custom/app.urls';

export const NavigationItems = [
  {
    title: 'modules',
    tags: 'modules',
    icon: 'fal fa-angle-down',
    routerLink: 'null',
    externalUrl: `${getBaseFrontendUrl(SYSTEM_NAME_AUTH)}`,
    roles: UserRoles.All
  },
  {
    title: 'calendar',
    tags: 'application intel',
    icon: 'fal fa-info-circle',
    routerLink: '/calendar',
    roles: UserRoles.All
  },
  {
    title: 'events',
    tags: 'application intel',
    icon: 'fal fa-info-circle',
    routerLink: '/event',
    roles: UserRoles.All
  },
  {
    title: 'newEvent',
    tags: 'application intel',
    icon: 'fal fa-info-circle',
    routerLink: '/event/new',
    roles: [UserRoles.RoombookAdmin, UserRoles.Superadmin]
  },
  {
    title: 'prices',
    tags: 'application intel',
    icon: 'fal fa-info-circle',
    routerLink: '/prices',
    roles: [UserRoles.RoombookAdmin, UserRoles.Superadmin]
  },
];
