import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { mobileNavigation } from 'src/app/core-frontend-common/template/store/navigation';
import { APP_CONFIG } from 'src/app/app.config';
import { AuthenticationService } from '../../../../auth/authentication.service';
import { AppCommunicationService } from '../../../../../+app-custom/services/app-communication.service';
import { SYSTEM_NAME } from "../../../../../+app-custom/app.settings";
import { SYSTEM_NAME_AUTH, SYSTEM_NAME_EPC, SYSTEM_NAME_UIK, UserRoles } from "../../../../../+app-custom/constants";
import { ActivatedRoute, Router } from "@angular/router";
import { UserRolePipe } from "../../../../pipes/user-role.pipe";

@Component({
  selector: 'smart-page-header',
  templateUrl: './page-header.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styles: [
    `
      .search {
        display: flex;
          gap: 15px;
          align-items: center;
          height: 100%;
          color: var(--header-text-color);
      }

      .logo-image {
        height: 60%;
        /*margin-top: 12px;*/
        vertical-align: middle;
      }

      @media only screen and (max-width: 600px) {
          .logo-image {
              
              height: 40%;
              
          }
      }

      .workplace-reset {
        height: 40px;
        margin-top: 10px;
      }
      
      .system-name {
          display: inline-flex;
          cursor: pointer;
          flex-direction: column;
          font-size: 0.9rem;
          line-height: 1.02rem;
          gap: 5px;
          width: 100%;
      }
    `,
  ],
})
export class PageHeaderComponent {
  systemFullName = APP_CONFIG.appName;
  avatar = APP_CONFIG.avatar;
  email = APP_CONFIG.email;
  user = APP_CONFIG.user;

  systemName = SYSTEM_NAME;

  systemNameUik = SYSTEM_NAME_UIK;

  systemNameEpc = SYSTEM_NAME_EPC;

  isUserLoggedIn = false;

  // only UIK
  workplace = '';

  showMenuBtn = false;

  clickCount = 0;

  pageTitle = '';

  constructor(
    private store: Store<any>,
    protected authService: AuthenticationService,
    protected appService: AppCommunicationService,
    protected userRole: UserRolePipe,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    this.isUserLoggedIn = this.authService.checkUser();

    // @ts-ignore
    this.showMenuBtn = SYSTEM_NAME !== SYSTEM_NAME_AUTH;

    if (this.route && this.route.snapshot && this.route.snapshot.firstChild && this.route.snapshot.firstChild.data) {
      this.pageTitle = this.route.snapshot.firstChild.data['pageTitle'];
    }

    // only UIK
    // if (this.systemFullName.toLowerCase() === 'uik') {
    //   this.workplace = AppCommunicationService.getWorkplaceFromLocalStorage();
    //
    //   this.appService.isWorkplaceChanged.subscribe(
    //     () => (this.workplace = AppCommunicationService.getWorkplaceFromLocalStorage()),
    //   );
    // }
  }

  openMobileNav($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(mobileNavigation({ open: true }));
  }

  clickedSystemName() {

    const role = this.userRole.transform([UserRoles.Superadmin]);

    if (role) {
      this.clickCount++;
    }

    if (this.clickCount && this.clickCount > 5) {
      this.router.navigate(['/dev-test']);
      this.clickCount = 0;
    }

  }

  // resetWorkplace() {
  //   this.appService.deleteWorkplaceFromLocalStorage();
  // }
}
