import { NgModule } from "@angular/core";
import { UserRolePipe } from "./user-role.pipe";
import { UserLoginPipe } from "./user-login.pipe";

@NgModule({
  declarations: [
    UserRolePipe,
    UserLoginPipe
  ],
  exports: [
    UserRolePipe,
    UserLoginPipe
  ],
  providers: [
    UserRolePipe,
    UserLoginPipe
  ]
})

export class CommonSharedPipesModule {}
