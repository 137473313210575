export class UserModel {
  id: number;
  login: string;
  name: string;
  surname: string;
  title: string;
  titleAfter: string;
  activeStatusCode: string;
  employmentCode: string;
  email: string;
  employmentDate: string;
  facultyCode: string;
  workCenterCode: string;
  photoOID: string;
  phone: string;
  historicalParentId: number;
  createdDatetime: string;
}
