import { Component, OnInit, ViewChild } from '@angular/core';
import { EventModel } from '../../models/event.model';
import { AppCommunicationService } from '../../services/app-communication.service';
import { EventSharedService } from '../../services/event-shared.service';
import { SharedDateService } from '../../services/shared-date.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { EventService } from '../../services/event.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-events-view',
  templateUrl: './events-view.component.html',
  styleUrls: ['./events-view.component.scss'],
})
export class EventsViewComponent implements OnInit {
  currentDate: string;
  /* ### Filter by room variable ### */
  filterRoom = null;
  /* ### FilteredEvents events ### */
  filteredEvents: EventModel[] = [];
  /* ### Loading indicator if data are loaded ### */
  loadingIndicator = true;
  /* ### All events ### */
  events: EventModel[] = [];
  showAllFilter = false;

  filters = {
    rooms: [
      {
        id: 1,
        color: '#92a2a8',
        acronym: 'VKM',
        title: 'Veľká konferenčná miestnosť',
      },
      {
        id: 5,
        color: '#356e35',
        acronym: 'MKM',
        title: 'Malá konferenčná miestnosť',
      },
      {
        id: 3,
        color: '#6e3671',
        acronym: 'VS',
        title: 'Výstavná sieň',
      },
      {
        id: 8,
        color: '#C02631',
        acronym: 'Z6P',
        title: 'Zasadačka 6.poschodie',
      },
      {
        id: 7,
        acronym: 'S2P',
        color: '#DCE775',
        title: 'Študovňa 2.poschodie',
      },
      {
        id: 6,
        acronym: 'S4P',
        color: '#A1887F',
        title: 'Študovňa 4.poschodie',
      },
      {
        id: 9,
        acronym: 'S6P',
        color: '#90A4AE',
        title: 'Študovňa 6.poschodie',
      },
      {
        id: 0,
        color: '#0F1217',
        acronym: 'X',
        title: 'Všetky',
      },
    ],
    text: '',
  };

  dateOrder = {
    startDate: true,
    endDate: false,
  };

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

  constructor(
    private eventService: EventService,
    private router: Router,
    private appCommunicationService: AppCommunicationService,
    private eventSharedService: EventSharedService,
    private sharedDateService: SharedDateService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
  ) {
  }

  ngOnInit() {
    // this.getEvents();
    // this.filterByRoom(0);
  }

  getEvents() {
    this.getCurrentDate();

    this.eventService
      .getEvents(this.currentDate, true)
      .pipe(
        flatMap(events => {
          return this.orderEventsByDate(events);
        }),
      )
      .subscribe(reorderedEvents => {
        this.filteredEvents = reorderedEvents;
        this.loadingIndicator = false;
      });
  }

  getCurrentDate() {
    if (this.sharedDateService.activeDate) {
      this.currentDate = `${this.sharedDateService.activeDate[1]}${
        this.sharedDateService.activeDate[0] + 1 < 10 ? '0' : ''
      }${this.sharedDateService.activeDate[0] + 1}01`;
    } else {
      const date = new Date();
      this.currentDate = `${date.getFullYear()}${date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()}01`;
    }
  }

  /* ### Filter events by date from month select component ### */
  filterByDate(): Observable<EventModel[]> {
    return this.eventService
      .getEvents(
        `${this.sharedDateService.activeDate[1]}${this.sharedDateService.activeDate[0] + 1 < 10 ? '0' : ''}${this
          .sharedDateService.activeDate[0] + 1}01`,
        true,
      )
      .pipe(
        flatMap(events => {
          this.loadingIndicator = false;
          return this.orderEventsByDate(events);
        }),
      );
  }

  orderEventsByDate(events: EventModel[]): Observable<EventModel[]> {
    return of(
      events.sort((a, b) => {
        return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
      }),
    );
  }

  /* ### Filter events room ### */
  filterByRoom(room) {
    let acronym;

    if (room === 0) {
      this.filterRoom = null;
    } else {
      const filteredRoom = this.filters.rooms.find(r => r.id == room);
      if (filteredRoom) {
        acronym = filteredRoom.acronym;
        this.filterRoom = filteredRoom.id;
      }
    }

    this.filterByDate()
      .subscribe(reorderedEvents => {
        if (this.filterRoom > 0) {
          this.filteredEvents = reorderedEvents;
          const eventsByRoom = [];
          this.filteredEvents.forEach(event => {
            event.rooms.forEach(r => {
              if (r.codeText === acronym) {
                eventsByRoom.push(event);
              }
            });
          });
          this.filteredEvents = eventsByRoom;
          this.events = this.filteredEvents;
          this.reorderFilteredEvents(1);
          this.filterByText(this.filters.text);
        } else {
          this.filteredEvents = reorderedEvents;
          this.events = this.filteredEvents;
          this.filterByText(this.filters.text);
        }
      });
  }

  checkboxToggle() {
    this.showAllFilter = !this.showAllFilter;
    this.updateFilter(this.filters.text);
  }

  /* ### Text input filter ### */
  updateFilter(text: string) {
    if (!this.showAllFilter) {
      this.filteredEvents = this.events;
      this.filterByText(text.toLowerCase());
    } else {
      this.getAllEvents()
        .subscribe(val => {
          this.filteredEvents = val;
          this.filterByText(text.toLowerCase());
        });
    }
  }

  getAllEvents(): Observable<any> {
    return this.eventService
      .getEvents(null, true);
  }

  filterByText(text: string) {
    if (text) {
      const val = text;
      this.filteredEvents = this.filteredEvents.filter(d => {
        return d.name.toLowerCase().includes(val) || d.responsiblePersonName.toLowerCase().includes(val) || !val;
      });
    }
  }

  /* ### Function triggered when month-select event is fired ### */
  setDate(event) {
    this.filterByRoom(this.filterRoom);
  }

  /* ### On new event click ### */
  onNewEventClick() {
    this.eventSharedService.deselectEvent();
    this.eventSharedService.deselectEventDate();
    this.router.navigate(['/event/new']);
  }

  /* ### On edit button click ### */
  onRowSelect(event) {
    this.eventSharedService.setSelectedEvent(event);
    this.router.navigate(['/event/new']);
  }

  reorderFilteredEvents(type) {
    if (type === 1) {
      this.filteredEvents.sort((event1, event2) => {
        return +new Date(event1.startDate) - +new Date(event2.startDate);
      });
    } else if (type === 2) {
      this.dateOrder.endDate = !this.dateOrder.endDate;
      if (this.dateOrder.endDate) {
        this.filteredEvents.sort((event1, event2) => {
          return +new Date(event1.endDate) - +new Date(event2.endDate);
        });
      } else {
        this.filteredEvents.sort((event1, event2) => {
          return +new Date(event2.endDate) - +new Date(event1.endDate);
        });
      }
    }
  }

  printTable() {
    let popupWin;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    const rows = this.getPrintableData();
    popupWin.document.write(
      `${'<html>' +
      '<head>' +
      '<link rel="stylesheet" type="text/css" href="/assets/css/custom/smartadmin-ngx-datatable.css" />' +
      '<link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />' +
      '<style>h1{text-align: center;}table{margin: 0 auto; width: 100%;}' +
      'table,th,td{border: 1px solid black; text-align: center; padding: 5px;}p{padding: 10px;}</style>' +
      '</head>' +
      '<body onload="window.print()">' +
      '<h1>Rozpis miestností za mesiac '}${this.sharedDateService
        .getMonthByIndex(this.sharedDateService.activeDate[0])
        .toUpperCase()
        .bold()}, ${this.sharedDateService.activeDate[1].toString().bold()}</h1>` +
      `<table>` +
      `<tr><th>Od</th><th>Do</th><th>Názov</th><th>Miestnosti</th></tr>${rows}</table>` +
      `<p><strong>VKM - </strong>Veľká konferenčná miestnosť, <strong>MKM - </strong>` +
      `Malá konferenčná miestnosť, <strong>VS - </strong>Výstavná sieň, <strong>Z6P - ` +
      `</strong>Zasadačka 6. poschodie</p>` +
      `</body>` +
      `</html>`,
    );
    popupWin.document.close();
  }

  getPrintableData(): string {
    let rows = '';
    if (this.filteredEvents !== null) {
      for (let i = 0; i < this.filteredEvents.length; i++) {
        rows =
          `${rows}<tr>` +
          `<td>${moment(this.filteredEvents[i].startDate).format('DD.MM.YYYY')},${moment(
            this.filteredEvents[i].startTime,
          ).format('HH:mm')}</td>` +
          `<td>${moment(this.filteredEvents[i].endDate).format('DD.MM.YYYY')},${moment(
            this.filteredEvents[i].endTime,
          ).format('HH:mm')}</td>` +
          `<td>${this.filteredEvents[i].name}</td>` +
          `<td>${this.filteredEvents[i].rooms.map(room => room.codeText)}</td>` +
          `</tr>`;
      }
    }
    return rows;
  }

  onFacturatedChange(event) {
    this.confirmationService.confirm({
      message: 'Snažíte sa upraviť fakturáciu. Skutočne si prajete vykonať akciu?',
      header: 'Úprava fakturácie',
      accept: () => {
        const rooms = [];
        const offeredServices = [];
        event.rooms.forEach(room => {
          rooms.push(room.id);
          delete room.codeText;
          delete room.color;
        });
        delete event.rooms;
        event.rooms = rooms;
        event.offeredServices.forEach(service => {
          offeredServices.push(service.id);
        });
        delete event.offeredServices;
        event.offeredServices = offeredServices;
        event.isInvoiced = !event.isInvoiced;
        delete event.createdByPerson;
        delete event.updatedByPerson;

        this.eventService.updateEvent(event).subscribe(
          () => {
            this.getEvents();
            this.successMessage();
          },
          () => {
            this.getEvents();
            this.errorMessage();
          },
        );
      },
    });
  }

  successMessage() {
    this.toastr.success('Úprava fakturácie prebehla v poriadku.', 'Fakturácia upravená');
  }

  errorMessage() {
    this.toastr.error('Vyskytla sa chyba počas úpravy fakturácie.', 'Chyba');
  }
}
