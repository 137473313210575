import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SharedDateService } from '../../services/shared-date.service';

@Component({
  selector: 'app-month-select',
  templateUrl: './month-select.component.html',
  styleUrls: ['./month-select.component.scss'],
})
export class MonthSelectComponent implements OnInit {
  months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'jule',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];
  now = [new Date().getMonth(), new Date().getFullYear()];
  @Output() onDateSelect: EventEmitter<any> = new EventEmitter<any>();

  constructor(private sharedDateService: SharedDateService) {}

  ngOnInit() {}

  onDateChange() {
    this.onDateSelect.emit(this.sharedDateService.activeDate);
  }

  getSharedDateService(): SharedDateService {
    return this.sharedDateService;
  }
}
