import {NgModule} from '@angular/core';
import {ReportBugDialogComponent} from './report-bug-dialog.component';
import {ModalModule} from 'ngx-bootstrap';
import {FormsModule} from '@angular/forms';
import {MatButtonModule, MatIconModule, MatInputModule} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { I18nModule } from '../../../core-frontend-common/template/shared/i18n/i18n.module';
import { ReportBugService } from './report-bug.service';

@NgModule({
  declarations: [
    ReportBugDialogComponent
  ],
  imports: [
    ModalModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    DragDropModule,
    I18nModule,
  ],
  exports: [
    ReportBugDialogComponent
  ],
  providers: [
    ReportBugService
  ]
})

export class ReportBugDialogModule {}
