import { Directive, ElementRef, OnInit, Input, Output, EventEmitter } from '@angular/core';

declare let $: any;

@Directive({
  selector: '[appSmartClockpicker]',
})
export class SmartClockpickerDirective implements OnInit {
  @Input() smartClockpicker: any;
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private el: ElementRef) {}

  ngOnInit() {
    // @ts-ignore
    import('clockpicker/dist/bootstrap-clockpicker.min.js').then(() => {
      this.render();
    });
  }

  render() {
    $(this.el.nativeElement).clockpicker(
      this.smartClockpicker || {
        placement: 'bottom',
        donetext: 'Done',
      },
    );
    $(this.el.nativeElement).on('change', () => {
      this.onChange.emit(this.el.nativeElement.value);
    });
  }
}
