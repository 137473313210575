import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserService } from '../../services/user.service';
import { AppCommunicationService } from '../../services/app-communication.service';
import { ModalDirective } from 'ngx-bootstrap';
import { GeneralService } from '../../services/general.service';
import { ParticipantModel } from '../../models/participant.model';
import { ExternalUserModalFormComponent } from '../external-user-modal-form/external-user-modal-form.component';
import { flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
})
export class UserSearchComponent implements OnInit {
  data = [];
  states = {
    opened: false,
    searching: false,
    submitted: false,
  };
  externalUserFormValid = false;
  faculties = [];
  workcenteres = [];
  userData = {
    faculty: {
      facultyId: null,
      facultyName: '',
    },
    workCenter: {
      departmentId: null,
      departmentyName: '',
    },
  };

  @Output() submittedParticipant: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteParticipant: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() selectedUser: ParticipantModel;
  @Input() withAddress: boolean;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChild('externalUserModal', { static: true }) externalUserModal: ModalDirective;
  @ViewChild(ExternalUserModalFormComponent, { static: true }) externalModalForm: ExternalUserModalFormComponent;

  constructor(
    private userService: UserService,
    private appCommunicationService: AppCommunicationService,
    private generalService: GeneralService,
  ) {}

  ngOnInit() {
    if (this.selectedUser.id || this.selectedUser.isExternal) {
      this.states.opened = true;
      this.states.submitted = true;
      this.getFaculties();
    }
    this.getFacultiesData();
  }

  getFacultiesData() {
    this.generalService.getFaculties().subscribe(faculties => (this.faculties = faculties));
  }

  getFaculties() {
    this.generalService
      .getFaculties()
      .pipe(
        flatMap(val => {
          this.faculties = val;
          return this.mapFacultyToUser(val, this.selectedUser);
        }),
      )
      .pipe(
        flatMap(faculty => {
          this.selectedUser.facultyId = faculty[0].facultyId;
          this.selectedUser.facultyName = faculty[0].facultyName;
          return this.mapWorkcenterToUser(faculty[0]);
        }),
      )
      .subscribe(workCenter => {
        workCenter.filter(dep => {
          if (dep.departmentId === this.selectedUser.workCenterId) {
            this.selectedUser.workCenterId = dep.departmentId;
            this.selectedUser.workCenterName = dep.departmentyName;
          }
        });
      });
  }

  mapFacultyToUser(faculties: any[], user): Observable<any> {
    return of(faculties.filter(faculty => faculty.facultyCode === user.facultyCode));
  }

  mapWorkcenterToUser(workcenter: any): Observable<any> {
    return this.getDepartmentByFacultyId(workcenter.facultyId);
  }

  getDepartmentByFacultyId(facultyId): Observable<any> {
    return this.generalService.getDepartments(facultyId);
  }

  getWorkCenterByFacultyId() {
    this.selectedUser.facultyId = this.userData.faculty.facultyId;
    this.selectedUser.facultyName = this.userData.faculty.facultyName;

    this.generalService.getDepartments(this.userData.faculty.facultyId).subscribe(val => {
      this.workcenteres = val;
    });
  }

  onWorkCenterChange() {
    this.selectedUser.workCenterId = this.userData.workCenter.departmentId;
    this.selectedUser.workCenterName = this.userData.workCenter.departmentyName;
  }

  onExternalUserSubmit(user) {
    this.selectedUser = user;
  }

  onExternalUserSubmitForm() {
    if (this.externalUserFormValid) {
      this.states.opened = true;
      this.states.searching = false;
      this.externalUserModal.hide();
    }
  }

  externalUserFormValidChange(val) {
    this.externalUserFormValid = !!val;
  }

  onFilterUsers(event) {
    this.userService.searchUser(event.query).subscribe(val => {
      if (val === null) {
        val = [];
        val.push({
          id: 0,
          title: 'Nenašli ste čo ste hľadali?',
          name: '',
          surname: '',
          titleAfter: '',
          workCenterCode: '',
          facultyCode: '',
        });
      } else {
        val.push({
          id: 0,
          title: 'Nenašli ste čo ste hľadali?',
          name: '',
          surname: '',
          titleAfter: '',
          workCenterCode: '',
          facultyCode: '',
        });
      }
      this.data = val;
    });
  }

  onSelectOption() {
    if (this.selectedUser.id === 0) {
      this.openExternalUserModal();
      this.externalModalForm.onNewUserForm();
      return;
    }
    this.appCommunicationService.loadingOn('Načítavam dáta.');
    this.states.searching = false;
    this.userService.getSearched(this.selectedUser).subscribe(
      user => {
        this.selectedUser = user;
        this.appCommunicationService.loadingOff();
      },
      () => {
        this.appCommunicationService.loadingOff();
        this.states.searching = true;
      },
    );
  }

  openExternalUserModal() {
    this.externalUserModal.show();
  }

  onSubmitParticipant() {
    this.submittedParticipant.emit(this.selectedUser);
    this.states.submitted = true;
  }

  toggleSearching() {
    this.states.searching = !this.states.searching;
  }

  onCloseButton() {
    this.states.opened = false;
    this.states.searching = false;
    if (this.states.submitted) {
      this.onDeleteParticipant.emit(true);
      this.states.submitted = false;
    }
  }

  onAddParticipantButton() {
    this.states.opened = true;
    this.states.searching = true;
  }
}
