import { Component, OnInit } from '@angular/core';
import { RoomService } from '../../services/room.service';
import { RoomModel } from '../../models/room.model';

@Component({
  selector: 'app-rooms-legend',
  templateUrl: './rooms-legend.component.html',
  styleUrls: ['./rooms-legend.component.scss'],
})
export class RoomsLegendComponent implements OnInit {
  rooms: RoomModel[] = [];
  mappedRooms = [];
  activeColor = '#fff';

  constructor(private roomService: RoomService) {}

  ngOnInit() {
    this.roomService.getRooms().subscribe(res => {
      this.rooms = res;
      this.mappedRooms = this.mapAcronymsToRooms(res);
    });
  }

  setActiveColor(color) {
    this.activeColor = color;
  }

  mapAcronymsToRooms(rooms: RoomModel[]) {
    const acronyms = [
      {
        code: 'bigConfRoom',
        acronym: 'VKM',
      },
      {
        code: 'smallConfRoom',
        acronym: 'MKM',
      },
      {
        code: 'expHall',
        acronym: 'VS',
      },
      {
        code: 'meet6floor',
        acronym: 'Z6P',
      },
    ];

    const mappedRooms = [];

    if (rooms !== null) {
      for (let i = 0; i < rooms.length; i++) {
        const room = rooms[i];
        room.acronym = acronyms.filter(obj => obj.code === room.code).map(obj => obj.acronym);
        mappedRooms.push(room);
      }
    }
    return mappedRooms;
  }
}
