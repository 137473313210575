import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserProfileViewComponent } from './+app-custom/views/user-profile-view/user-profile-view.component';
import { DevComponent } from './+app-custom/components/dev/dev.component';
import { EventsViewComponent } from './+app-custom/views/events-view/events-view.component';
import { NewEventViewComponent } from './+app-custom/views/new-event-view/new-event-view.component';
import { PricesViewComponent } from './+app-custom/views/prices-view/prices-view.component';
import { CalendarViewComponent } from './+app-custom/views/calendar-view/calendar-view.component';
import { AuthGuard } from './core-frontend-common/auth/guards/auth.guard';
import { RoleGuard } from './core-frontend-common/auth/guards/role.guard';
import { MainComponent } from './core-frontend-common/template/shared/layout/main/main.component';
import { LayoutModule } from './core-frontend-common/template/shared/layout/layout.module';

const routes: Routes = [
  { path: '', redirectTo: 'calendar', pathMatch: 'full', canActivate: [AuthGuard] },
  {
    path: '',
    component: MainComponent,
    children: [
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      { path: 'dev', component: DevComponent, pathMatch: 'full' },
      { path: 'profile', component: UserProfileViewComponent, data: { pageTitle: 'profile' }, pathMatch: 'full' },
      { path: 'event', component: EventsViewComponent, data: { pageTitle: 'events' } },
      {
        path: 'event/new',
        component: NewEventViewComponent,
        data: { pageTitle: 'newEvent' },
        pathMatch: 'full',
      },
      {
        path: 'prices',
        component: PricesViewComponent,
        data: { pageTitle: 'prices', roles: ['SUPERADMIN', 'ROOMBOOK_ADMIN'] },
        canActivate: [RoleGuard],
      },
      { path: 'calendar', component: CalendarViewComponent, data: { pageTitle: 'calendar' } },
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [LayoutModule, RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
