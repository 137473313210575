import { Injectable } from '@angular/core';
import { OfferedServiceModel } from '../models/offeredservice.model';
import { getOfferedServicesUrl } from '../app.urls';
import { AppCommunicationService } from './app-communication.service';
import { PriceListService } from './price-list.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, flatMap, map } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { AuthenticationService } from '../../core-frontend-common/auth/authentication.service';

@Injectable()
export class OfferedServicesService {
  checkUnauthorizedAccess(error) {
    if (error.status === 401) {
      this.router.navigate(['/login']);
      return observableThrowError('Unauthorized');
    }
    return observableThrowError('error');
  }

  constructor(
    private http: HttpClient,
    private appCommunicationService: AppCommunicationService,
    private priceListService: PriceListService,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  getOfferedServices(): Observable<OfferedServiceModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
      }),
    };

    if (this.authenticationService.checkUser()) {
      return this.http.get(getOfferedServicesUrl(), httpOptions).pipe(
        flatMap((services: any) => {
          return this.priceListService.getRoomsPriceList().pipe(
            map(priceListRes => {
              return this.mapOfferedService(services.items as OfferedServiceModel[], priceListRes);
            }),
          );
        }),
        catchError(this.checkUnauthorizedAccess),
      );
    }
  }

  mapOfferedService(services: OfferedServiceModel[], priceList): OfferedServiceModel[] {
    const acronyms = ['moveFull', 'moveAfter', 'staff'];
    const mappedOfferedService: OfferedServiceModel[] = [];

    if (services !== undefined) {
      for (let i = 0; i < services.length; i++) {
        const serviceItem = services[i];
        serviceItem.flatPrice = priceList.find(o => o.code === serviceItem.code).flatPrice;
        serviceItem.hourlyPrice = priceList.find(o => o.code === serviceItem.code).hourlyPrice;
        serviceItem.code = acronyms[i];
        mappedOfferedService.push(serviceItem);
      }
    }
    return mappedOfferedService;
  }
}
