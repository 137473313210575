import { Component, OnInit } from '@angular/core';
import { APP_CONFIG } from 'src/app/app.config';
import { SYSTEM_NAME } from "../../../../../+app-custom/app.settings";
import { AppCommunicationService } from "../../../../../+app-custom/services/app-communication.service";

@Component({
  selector: 'smart-page-footer',
  templateUrl: './page-footer.component.html',
  styles: [
    `
    .page-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        color: var(--footer-text-color);
    }
    `
  ],
})
export class PageFooterComponent implements OnInit {
  currentYear = new Date().getFullYear();

  constructor() {}

  ngOnInit() {}

  openReportDialog(e: Event) {
    e.preventDefault();

    ReportBug.openDialog(
      SYSTEM_NAME,
      AppCommunicationService.getCurrentUser().user,
      () => {},
      (error) => {},
      () => {
      }
    ); // Call the function from the ReportBug module
  }
}
