import { Theme } from "./theme";

export enum ThemesEnum {
  DEFAULT = 'default'
}

export const themesList: Theme[] = [
  {
    name: ThemesEnum.DEFAULT,
    properties: {
      '--header-bg': '#111',
      '--footer-bg': '#111',
      '--sidebar-bg': '#111',

      '--header-text-color': '#fff',
      '--footer-text-color': '#A0A0A0',

      '--tuke-color': '#A59466'
    }
  }
]
