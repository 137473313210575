import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserProfileViewComponent } from './views/user-profile-view/user-profile-view.component';
import { AppCommunicationService } from './services/app-communication.service';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { CustomPipesModule } from './pipes/customPipes.module';
import { TileComponent } from './components/tile/tile.component';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { ExternalUserModalFormComponent } from './components/external-user-modal-form/external-user-modal-form.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ModalModule, PopoverModule, TooltipModule } from 'ngx-bootstrap';
import { CalendarModule } from 'primeng/calendar';
import { DevComponent } from './components/dev/dev.component';
import { CalendarViewComponent } from './views/calendar-view/calendar-view.component';
import { EventsViewComponent } from './views/events-view/events-view.component';
import { NewEventViewComponent } from './views/new-event-view/new-event-view.component';
import { PricesViewComponent } from './views/prices-view/prices-view.component';
import { ConfirmationService, ConfirmDialogModule, InputTextModule, SidebarModule } from 'primeng/primeng';
import { DeleteEventModalComponent } from './components/delete-event-modal/delete-event-modal.component';
import { FloatPriceTableComponent } from './components/float-price-table/float-price-table.component';
import { MonthSelectComponent } from './components/month-select/month-select.component';
import { EventSharedService } from './services/event-shared.service';
import { SharedDateService } from './services/shared-date.service';
import { OfferedServicesService } from './services/offered-services.service';
import { RoomService } from './services/room.service';
import { EventService } from './services/event.service';
import { PriceListService } from './services/price-list.service';
import { UserPipe } from './pipes/user.pipe';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { TableModule } from 'primeng/table';
import { BtnGroupFilterComponent } from './components/btn-group-filter/btn-group-filter.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { I18nModule } from '../core-frontend-common/template/shared/i18n/i18n.module';
import { SharedModule } from '../core-frontend-common/template/shared/shared.module';
import { SmartClockpickerDirective } from '../core-frontend-common/template/shared/utils/smart-clockpicker.directive';
import { RoomsLegendComponent } from './components/rooms-legend/rooms-legend.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FloatNumberFormatDirective } from './directives/floatNumberFormat.directive';
import { FocusDirective } from './directives/focus.directive';
import { RoomPipe } from './pipes/room.pipe';
import { NotificationModule } from './components/notification/notification.module';
import { CalendarEventDetailComponent } from './views/calendar-view/calendar-event-detail/calendar-event-detail.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    I18nModule,
    CustomPipesModule,
    RouterModule,
    AutoCompleteModule,
    ModalModule,
    CalendarModule,
    TooltipModule,
    FullCalendarModule,
    TableModule,
    InputTextModule,
    PopoverModule,
    ConfirmDialogModule,
    SharedModule,
    NgMultiSelectDropDownModule,
    TooltipModule,
    NotificationModule,
    SidebarModule,
  ],
  declarations: [
    DashboardComponent,
    EventsViewComponent,
    NewEventViewComponent,
    PricesViewComponent,
    CalendarViewComponent,
    MonthSelectComponent,
    FloatPriceTableComponent,
    UserProfileViewComponent,
    DashboardComponent,
    TileComponent,
    UserSearchComponent,
    ExternalUserModalFormComponent,
    RoomsLegendComponent,
    UserPipe,
    DeleteEventModalComponent,
    DevComponent,
    SmartClockpickerDirective,
    BtnGroupFilterComponent,
    FloatNumberFormatDirective,
    FocusDirective,
    RoomPipe,
    CalendarEventDetailComponent
  ],
  exports: [EventsViewComponent, NewEventViewComponent, PricesViewComponent, CalendarViewComponent],
  providers: [
    PriceListService,
    EventService,
    AppCommunicationService,
    RoomService,
    OfferedServicesService,
    SharedDateService,
    EventSharedService,
    ConfirmationService,
    DeviceDetectorService
  ],
})
export class CustomModule {}
