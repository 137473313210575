import { Component, OnInit } from '@angular/core';
import { SystemUserModel } from '../../models/system-user.model';
import { UserService } from '../../services/user.service';
import { AppCommunicationService } from '../../services/app-communication.service';
import { AuthenticationService } from '../../../core-frontend-common/auth/authentication.service';

@Component({
  selector: 'app-user-profile-view',
  templateUrl: './user-profile-view.component.html',
  styleUrls: ['./user-profile-view.component.scss'],
})
export class UserProfileViewComponent implements OnInit {
  user: SystemUserModel;
  isImageLoading = false;
  userImage: any;

  constructor(private _userService: UserService) {
    this.getImageFromService();
  }

  ngOnInit() {
    this.getLoggedUser();
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.userImage = reader.result;
      },
      false,
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getImageFromService() {
    this.isImageLoading = true;
    this._userService.getPhotoForUser(AppCommunicationService.getCurrentUser().user.photoOID).subscribe(
      data => {
        this.createImageFromBlob(data);
        this.isImageLoading = false;
      },
      error => {
        this.isImageLoading = false;
        console.log(error);
      },
    );
  }

  getLoggedUser() {
    this.user = AuthenticationService.getCurrentUser().user as SystemUserModel;
  }

  // participants = [new ParticipantModel()];
  // @Output() sectionValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  //
  // onSubmitParticipant(value, index) {
  //     this.participants[index] = value;
  //     this.participants.push(new ParticipantModel());
  //     this.checkSectionValidity();
  // }
  //
  // onDeleteParticipant(index) {
  //     this.participants.splice(index, 1);
  //     this.checkSectionValidity();
  // }
  //
  // checkSectionValidity() {
  //     if (
  //         this.participants.length > 1 &&
  //         this.participants.some(participant => participant.name !== undefined || participant.name !== null)
  //     ) {
  //         this.sectionValid.emit(true);
  //         return true;
  //     }
  //     this.sectionValid.emit(false);
  //     return false;
  // }
}
