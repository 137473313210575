import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store, createSelector } from '@ngrx/store';
import { selectNavigationItems, NavigationItem } from 'src/app/core-frontend-common/template/store/navigation';
import { selectSettingsState } from 'src/app/core-frontend-common/template/store/settings';
import { ReportBugService } from '../../../../components/report-bug/report-bug.service';
import { SYSTEM_FULL_NAME_LIBADMIN, UserRoles } from '../../../../../+app-custom/constants';
import { SYSTEM_FULL_NAME } from '../../../../../+app-custom/app.settings';

@Component({
  selector: 'smart-nav',
  templateUrl: './nav.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  vm$ = this.store.select(
    createSelector(
      selectNavigationItems,
      selectSettingsState,
      (items, settings) => ({ items, minified: settings.minifyNavigation })
    )
  );

  UserRoles = UserRoles;

  constructor(
    private store: Store<any>,
    protected reportBugDialogService: ReportBugService
  ) {
  }

  trackByFn(idx: number, item: NavigationItem) {
    return `${item.title}_${idx}`;
  }

  reportProblemClicked(e: Event) {
    e.stopPropagation();
    this.reportBugDialogService.reportBug.next(true);
  }

  showErrorReports(): boolean {
    // @ts-ignore
    return SYSTEM_FULL_NAME !== SYSTEM_FULL_NAME_LIBADMIN;
  }

}
