import { Component, OnInit, ViewChild } from '@angular/core';
import { EventService } from '../../services/event.service';
import { RoomService } from '../../services/room.service';
import { AppCommunicationService } from '../../services/app-communication.service';
import { EventSharedService } from '../../services/event-shared.service';
import { SharedDateService } from '../../services/shared-date.service';
import { UserRolePipe } from '../../pipes/user-role.pipe';
import { Router } from '@angular/router';
import { EventModel } from '../../models/event.model';
import { RoomModel } from '../../models/room.model';
import { FullCalendar } from 'primeng/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

import * as moment from 'moment';

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss'],
})
export class CalendarViewComponent implements OnInit {
  events: EventModel[] = [];
  rooms: RoomModel[] = [];
  view = 'dayGridMonth';
  clickedDay: any;
  isClickedDay = false;
  currentDate = '';
  defaultDate = new Date();
  calendarEvents: any[] = [];
  calendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
    defaultDate: this.defaultDate,
    slotLabelFormat: 'H:mm',
    locale: 'sk',
    contentHeight: 'auto',
    height: 700,
    header: {
      left: '',
      center: 'title',
      right: '',
    },
    timeFormat: 'H:mm',
    cmdFormatter: 'H:mm',
    dateClick: e => {
      this.onDayClick(e);
    },
    eventRender: e => {
      this.onEventRender(e);
    },
    eventClick: event => {
      this.onEventClick(event);
    }
  };

  eventToShow: EventModel;

  showEventDetail = false;

  @ViewChild('fc', { static: true }) fullCalendar: FullCalendar;

  constructor(
    private eventService: EventService,
    private roomService: RoomService,
    private appCommunicationService: AppCommunicationService,
    private eventSharedService: EventSharedService,
    private sharedDateService: SharedDateService,
    private router: Router,
    private userRolePipe: UserRolePipe,
  ) {
  }

  ngOnInit() {
    this.getCurrentDate();

    if (!this.userRolePipe.transform(['SUPERADMIN', 'ROOMBOOK_ADMIN'])) {
      this.view = 'timeGridWeek';
    }

    this.eventService.getEvents(this.currentDate, false).subscribe(
      res => {
        this.events = res;
        this.mapEventsToCalendar();
      },
      error2 => {
        console.log(error2);
      },
    );

    if (this.sharedDateService.activeDate) {
      this.defaultDate.setFullYear(this.sharedDateService.activeDate[1]);
      this.defaultDate.setMonth(this.sharedDateService.activeDate[0]);
    } else {
      this.defaultDate = new Date();
    }
  }

  getCurrentDate() {
    if (this.sharedDateService.activeDate) {
      this.currentDate = `${this.sharedDateService.activeDate[1]}${
        this.sharedDateService.activeDate[0] + 1 < 10 ? '0' : ''
      }${this.sharedDateService.activeDate[0] + 1}01`;
    } else {
      const date = new Date();
      this.currentDate = `${date.getFullYear()}${date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()}${
        date.getDay() < 10 ? `0${date.getDay()}` : date.getDay()
      }`;
    }
  }

  onEventRender(event) {
    event.textColor = 'white';
    console.log(event);
  }

  dateChange(action: number) {
    switch (action) {
      case 1:
        if (this.view === 'dayGridMonth') {
          this.sharedDateService.previousMonth();
        }
        this.fullCalendar.calendar.prev();
        break;
      case 2:
        if (this.view === 'dayGridMonth') {
          this.sharedDateService.deselectActiveDate();
        }
        this.fullCalendar.calendar.today();
        break;
      case 3:
        if (this.view === 'dayGridMonth') {
          this.sharedDateService.nextMonth();
        }
        this.fullCalendar.calendar.next();
        break;
    }

    if (this.view === 'timeGridWeek') {
      this.sharedDateService.setDateByWeek(this.fullCalendar.calendar.getDate());
    }

    this.getEvents();
  }

  getEvents() {
    this.eventService
      .getEvents(
        `${this.sharedDateService.activeDate[1]}${this.sharedDateService.activeDate[0] + 1 < 10 ? '0' : ''}${this
          .sharedDateService.activeDate[0] + 1}01`,
        false,
      )
      .subscribe(
        res => {
          this.events = res;
          this.mapEventsToCalendar();
        },
        error2 => {
          console.log(error2);
        },
      );
  }

  mapEventsToCalendar() {
    this.calendarEvents = [];
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].rooms) {
        for (let j = 0; j < this.events[i].rooms.length; j++) {
          const startDate = moment(this.events[i].startDate, 'YYYY-MM-DDTHH:mm:ss');
          const endDate = moment(this.events[i].endDate, 'YYYY-MM-DDTHH:mm:ss');
          startDate.set('hour', new Date(this.events[i].startTime).getHours());
          startDate.set('minute', new Date(this.events[i].startTime).getMinutes());
          endDate.set('hour', new Date(this.events[i].endTime).getHours());
          endDate.set('minute', new Date(this.events[i].startTime).getMinutes());
          this.calendarEvents.push({
            start: startDate.format('YYYY-MM-DDTHH:mm:ss'),
            end: endDate.format('YYYY-MM-DDTHH:mm:ss'),
            title: this.events[i].name,
            backgroundColor: this.events[i].rooms[j].color,
            acronym: this.events[i].rooms[j].code,
            object: this.events[i]
          });
        }
      }
    }
    this.fullCalendar.calendar.refetchEvents();
  }

  onDayClick(event) {
    this.clickedDay = event.date;
    this.isClickedDay = true;
  }

  onEventClick(event) {
    if (event && event.event && event.event.extendedProps && event.event.extendedProps.object) {

      this.eventToShow = event.event.extendedProps.object;

      this.showEventDetail = true;

    }
  }

  onNewEventClick() {
    this.eventSharedService.deselectEvent();

    if (this.isClickedDay) {
      this.eventSharedService.setSelectedEventDate(this.clickedDay);
    } else {
      this.eventSharedService.deselectEventDate();
    }

    this.router.navigate(['/event/new']);
  }
}
