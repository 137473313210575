export const languages: any = [
  {
    key: 'sk',
    alt: 'Slovensko',
    title: 'Slovensko (SK)',
  },
  {
    key: 'us',
    alt: 'United States',
    title: 'English (US)',
  },
];
