import { UserModel } from './user.model';

export class EventModel {
  id: number;
  startDate: string;
  endDate: string;
  name: string;
  responsiblePersonName: string;
  countPeople: number;
  comment: string;
  paymentType: number;
  rooms: any[];
  discount: number;
  offeredServices: any[];
  dealedPrice: number;
  catalogPrice: number;
  startTime: string;
  endTime: string;
  createdById: string;
  createdDatetime: string;
  updatedById: any;
  updatedDatetime: string;
  employeeCount: number;
  email: string;
  isInvoiced?: boolean;

  createdBy: UserModel;
  updatedBy: UserModel;

}
