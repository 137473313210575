import { Component, Input, OnInit } from '@angular/core';
import { EventModel } from '../../../models/event.model';

@Component({
  selector: 'smart-calendar-event-detail',
  templateUrl: './calendar-event-detail.component.html',
  styleUrls: ['./calendar-event-detail.component.scss'],
})
export class CalendarEventDetailComponent implements OnInit {

  @Input() event: EventModel;

  constructor() {
  }

  ngOnInit() {
  }

}
