import { Injectable } from '@angular/core';
import { EventModel } from '../models/event.model';

@Injectable()
export class EventSharedService {
  selectedEventDate: any;
  isSelectedEventDate = false;
  selectedEvent: EventModel;
  isSelectedEvent = false;

  constructor() {}

  /* ##### Selected date from calendar ##### */
  setSelectedEventDate(date) {
    this.selectedEventDate = date;
    this.isSelectedEventDate = true;
  }

  deselectEventDate() {
    this.isSelectedEventDate = false;
  }

  /* ##### Selected event from events-view ##### */
  setSelectedEvent(event: EventModel) {
    this.selectedEvent = event;
    this.isSelectedEvent = true;
  }

  deselectEvent() {
    this.isSelectedEvent = false;
  }
}
