import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NavigationItem, toggleNavSection } from 'src/app/core-frontend-common/template/store/navigation';
import { Store } from '@ngrx/store';
import { makeSlideInOut } from '../../utils/animations';
import { ShortcutModalService } from '../../layout/shortcut-modal/shortcut-modal.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[smart-nav-item]',
  templateUrl: './nav-item.component.html',
  animations: [makeSlideInOut()],
  styles: [
    `
      .modules {
        text-align: center !important;
        color: white !important;
        text-transform: uppercase;
        cursor: pointer;
        padding: 0.8125rem 2rem !important;
      }

      hr {
        border-bottom: 1px solid rgba(238, 238, 238, 0.26);
      }
      
      .nav-link-text {
          color: #bebebf;
      }
      
      a {
          cursor: pointer;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NavItemComponent {
  @Input() item: NavigationItem;
  @Input() minified: boolean;

  @HostBinding('class.active') get active() {
    return this.item.active;
  }
  @HostBinding('class.open') get open() {
    return this.item.open;
  }
  @HostBinding('class.js-filter-hide]') get hidden() {
    return this.item.matched !== null && !this.item.matched;
  }
  @HostBinding('class.js-filter-show]') get shown() {
    return this.item.matched !== null && this.item.matched;
  }
  // @HostBinding('class.nav-title]') get navTitle() { return this.item.navTitle; }

  constructor(private store: Store<any>, public shortcut: ShortcutModalService) {}

  toggleSection($event: MouseEvent) {
    $event.preventDefault();
    if (this.item && (this.item.routerLink || this.item.items.length > 0)) {
      this.store.dispatch(toggleNavSection({ item: this.item }));
    }
  }

  trackByFn(idx: number, item: NavigationItem) {
    return `${item.title}_${idx}`;
  }

  openShortcuts($event: MouseEvent) {
    $event.preventDefault();
    this.shortcut.openModal();
  }
}
