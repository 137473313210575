// Sem patria globalne konstanty

export const SYSTEM_NAME_AUTH = 'AUTH';
export const SYSTEM_NAME_EPC = 'EPC';
export const SYSTEM_NAME_ETD = 'ETD';
export const SYSTEM_NAME_ISBN = 'ISBN';
export const SYSTEM_NAME_ROOMBOOK = 'ROOMBOOK';
export const SYSTEM_NAME_ZP = 'ZP';
export const SYSTEM_NAME_UIK = 'UIK';
export const SYSTEM_NAME_HELPDESK = 'HELPDESK';
export const SYSTEM_NAME_TOOLS = 'TOOLS';
export const SYSTEM_NAME_LIBADMIN = 'LIBADMIN';
export const SYSTEM_NAME_WAREHOUSE = 'WAREHOUSE';
export const SYSTEM_NAME_ALFRED = 'ALFRED';
export const SYSTEM_NAME_HELIOS = 'HELIOS';

export const SYSTEM_FULL_NAME_ETD = 'thesisEvidence';
export const SYSTEM_FULL_NAME_ROOMBOOK = 'roombook';
export const SYSTEM_FULL_NAME_WAREHOUSE = 'warehouse';
export const SYSTEM_FULL_NAME_LIBADMIN = 'libadmin';
/*
 * USER ROLES
 *
 *
 *
 */
const USER_ROLES = {
  SUPERADMIN: 'SUPERADMIN',
  ROOMBOOK_ADMIN: 'ROOMBOOK_ADMIN',
  ROOMBOOK: 'ROOMBOOK'
} as const;

export const UserRoles = {
  All: [
    USER_ROLES.SUPERADMIN,
    USER_ROLES.ROOMBOOK_ADMIN,
    USER_ROLES.ROOMBOOK
  ],
  Superadmin: USER_ROLES.SUPERADMIN,
  RoombookAdmin: USER_ROLES.ROOMBOOK_ADMIN,
  Roombook: USER_ROLES.ROOMBOOK
} as const;

export const ROOM_DISCOUNTS = [
  { name: 'noDisc', value: 0, checked: false },
  { name: 'basicDisc', value: 10, checked: false },
  { name: 'combinedDisc', value: 20, checked: false },
];

export const EMPLOYEE_OVERTIME_HOUR_PRICE = 15;

export const skLocalizationCalendar = {
  firstDayOfWeek: 1,
  dayNames: ['Nedeľa', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
  dayNamesShort: ['Ne', 'Po', 'Ut', 'St', 'Št', 'Pi', 'So'],
  dayNamesMin: ['Ne', 'Po', 'Ut', 'St', 'Št', 'Pi', 'So'],
  monthNames: [
    'Január',
    'Február',
    'Marec',
    'Apríl',
    'Máj',
    'Jún',
    'Júl',
    'August',
    'September',
    'Október',
    'November',
    'December',
  ],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
  today: 'dnes',
  clear: 'Zmazať',
};

export const ROOMS = [
  {
    id: 1,
    color: '#92a2a8',
    acronym: 'VKM',
    title: 'Veľká konferenčná miestnosť',
  },
  {
    id: 2,
    color: '#356e35',
    acronym: 'MKM',
    title: 'Malá konferenčná miestnosť',
  },
  {
    id: 3,
    color: '#6e3671',
    acronym: 'VS',
    title: 'Výstavná sieň',
  },
  {
    id: 4,
    color: '#C02631',
    acronym: 'Z6P',
    title: 'Zasadačka 6.poschodie',
  },
];


export const COLORS = {
  blueLight: '#92a2a8',
  blue: '#57889c',
  teal: '#568a89',
  blueDark: '#4c4f53',
  green: '#356e35',
  greenDark: '#496949',
  greenLight: '#71843f',
  purple: '#6e587a',
  magenta: '#6e3671',
  pink: '#ac5287',
  pinkDark: '#a8829f',
  yellow: '#b09b5b',
  orange: '#c79121',
  red: '#a90329',
  redLight: '#a65858'
};
