import { Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { mobileNavigation } from 'src/app/core-frontend-common/template/store/navigation';
import { APP_CONFIG } from 'src/app/app.config';
import { AppCommunicationService } from '../../../../../+app-custom/services/app-communication.service';
import { ReportBugDialogComponent } from '../../../../../+app-custom/components/report-bug-dialog/report-bug-dialog.component';
import { Subscription } from 'rxjs';
import { ReportBugService } from '../../../../components/report-bug/report-bug.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';

@Component({
  selector: 'smart-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  app = APP_CONFIG.app;
  loading = false;
  loadingText: string;

  reportBugSubscriber: Subscription;

  @ViewChild(ReportBugDialogComponent, {static: false}) reportBugDialog: ReportBugDialogComponent;

  constructor(
    private store: Store<any>,
    protected appCommunicationService: AppCommunicationService,
    protected reportBugService: ReportBugService,
    private deviceService: DeviceDetectorService,
    protected notificationService: ToastrService,
    protected router: Router
  ) {

    setTimeout(() => {

      this.appCommunicationService
        .loadingAnimation
        .subscribe(value => {
          this.loading = value;
        });

      this.appCommunicationService
        .loadingAnimationText
        .subscribe(val => {
          this.loadingText = val;
        });

    }, 50);

    this.reportBugSubscriber = this.reportBugService.reportBug.subscribe(() => {
      this.reportBugDialog.openModal();
    });

  }

  closeMobileNav($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(mobileNavigation({ open: false }));
  }

  onReportBugDialogSubmit(message: string) {
    if (message) {
      setTimeout(() => {
        this.clickedReportBug(message);
      }, 500);
    }
  }

  async clickedReportBug(message: string) {
    let deviceType = '';
    if (this.deviceService.isDesktop()) {
      deviceType = 'desktop';
    } else if (this.deviceService.isTablet()) {
      deviceType = 'tablet';
    } else if (this.deviceService.isMobile()) {
      deviceType = 'mobile';
    }

    const techData = {
      page: this.router.url.slice(0, 10),
      deviceInfo: this.deviceService.getDeviceInfo(),
      deviceType,
      screen: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };

    // @ts-ignore
    const canvas = await html2canvas(document.body);
    if (canvas) {
      const image = canvas.toDataURL('image/jpeg');

      // var a = document.createElement('a');
      // a.href = image;
      // a.download = "output.jpeg";
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);

      try {
        await this.reportBugService
          .send({
            techData: JSON.stringify(techData),
            screenShot: image,
            description: message,
            isSolved: false
          }).toPromise();
        console.log('Report bug: ', message, JSON.stringify(techData));
      } catch (e) {

        this.notificationService.error(
          'Nebolo možné odoslať hlásenie.',
          'Chyba');
        console.log(e);
        return;
      }

      this.notificationService.success(
        'Ďakujeme za spätnú väzbu. Vážime si to.',
        'Hlásenie bolo odoslané.');
    }
  }
}
