import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppCommunicationService } from './app-communication.service';
import { AuthenticationService } from '../../core-frontend-common/auth/authentication.service';
import { Router } from '@angular/router';
import { NotificationModel } from '../components/notification/notification.model';
import { getAllNotificationsForSystem } from '../app.urls';
import { PRODUCTION_BUILD, SYSTEM_NAME } from '../app.settings';
import { map } from 'rxjs/operators';
import { getDepartmentCodebookUrl, getFacultyCodebookUrl } from '../app.urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { mapDaysOfWeekToOpenHours } from '../misc/map-days-of-week-to-open-hours';
import { OpenHourModel } from '../models/open-hour.model';

@Injectable()
export class GeneralService {

  BASE_URL = 'https://api.uik.portal.tuke.sk/api/';

  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  getFaculties(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.get(getFacultyCodebookUrl(), httpOptions).pipe(map((res: any) => res.items));
    }
  }

  getDepartments(facultyId: number): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.get(getDepartmentCodebookUrl(facultyId), httpOptions).pipe(map((res: any) => res.items));
    }
  }

  getNotifications(): Observable<NotificationModel[]> {
    return this.http
      .post(getAllNotificationsForSystem(false, SYSTEM_NAME), {})
      .pipe(map((res: any) => res.items));
  }

  getOpenHours(workplace: string = 'study'): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded'
      })};

    return this.http
      .post(`${this.BASE_URL}openingHour/getOpeningHours`, `workplaceCode=${workplace}`, httpOptions)
      .pipe(
        map((res: OpenHourModel[]) => {
          return mapDaysOfWeekToOpenHours(res);
        })
      );
  }
}
