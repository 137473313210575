import { Component } from '@angular/core';
import { UserService } from '../../../../../+app-custom/services/user.service';
import { AuthenticationService } from '../../../../auth/authentication.service';
import { Router } from '@angular/router';
import { AppRedirectService } from '../../../../auth/app-redirect.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'smart-dropdown-user',
  templateUrl: './dropdown-user.component.html',
})
export class DropdownUserComponent {
  name: string;
  surname: string;
  email: string;
  isExternal: boolean;

  employments = [];
  activeEmployment = 0;

  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private router: Router,
    private appRedirectService: AppRedirectService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.getLoggedUser();
    this.getEmployments();
    this.checkActiveEmployment();
  }

  profileRedirect() {
    this.router.navigate(['/profile']);
  }

  logout() {
    this.authService.logout().subscribe(
      () => {
      },
      error2 => {
        console.log(error2);
      },
    );
  }

  getLoggedUser() {
    this.name = AuthenticationService.getCurrentUser().user.name;
    this.surname = AuthenticationService.getCurrentUser().user.surname;
    this.email = AuthenticationService.getCurrentUser().user.email;
    this.isExternal = AuthenticationService.getCurrentUser().user.isExternal;
  }

  getEmployments() {
    this.authService.getEmployments().subscribe(value => {
      this.employments = value;
    });
  }

  changeEmployment(employmentCode) {
    this.authService.changeEmployment(employmentCode).subscribe(
      () => {
        this.checkActiveEmployment();
        this.successMessage();
        setTimeout(() => location.reload(), 1000);
      },
      () => this.errorMessage(),
    );
  }

  checkActiveEmployment() {
    this.activeEmployment = AuthenticationService.getCurrentUser().user.employmentCode;
  }

  /* ##### Alert messages ##### */
  successMessage() {
    this.toastr.success('Zmena pracoviska prebehla úspešne.', 'Zmena pracoviska');
  }

  errorMessage() {
    this.toastr.error('Vyskytla sa chyba počas zmeny pracoviska. Za vzniknuté problémy sa ospravedlňujeme.', 'Chyba');
  }
}
