import { Component, ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { Store } from '@ngrx/store';
import { selectSettingsState } from 'src/app/core-frontend-common/template/store/settings';
import * as settings from "../../../store/settings";
import { Observable, Observer, Subscription } from "rxjs";

@Component({
  selector: 'smart-navigation',
  template: `
    <!-- BEGIN PRIMARY NAVIGATION -->
    <nav
      *ngIf="vm$ | async as vm"
      id="js-primary-nav"
      class="primary-nav"
      [class.h-100]="vm.fixedNavigation"
      role="navigation"
    >
      <!--      <smart-nav-filter></smart-nav-filter>-->
      <!--      <smart-nav-info-card></smart-nav-info-card>-->
      <smart-nav></smart-nav>
      <smart-nav-filter-msg></smart-nav-filter-msg>
    </nav>
    
<!--    <div class="minify-navigation-container">-->
<!--      <div class="minify-navigation-btn"-->
<!--           [tooltip]="isNavigationMinified ? ('Zväčšiť panel' | i18n) : ('Zmenšiť panel' | i18n)"-->
<!--           (click)="toggleNavigation()">-->
<!--        <i class="fa fal" [ngClass]="isNavigationMinified ? 'fa-arrow-right' : 'fa-arrow-left'"></i>-->
<!--      </div>-->
<!--    </div>-->
    
    <!-- END PRIMARY NAVIGATION -->
<!--    <div class="nav-footer-container">-->
<!--      <smart-nav-footer></smart-nav-footer>-->
<!--    </div>-->
  `,
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NavigationComponent implements OnDestroy {
  isNavigationMinified = false;

  vm$ = this.store.select(selectSettingsState);

  storeObserver: Subscription;

  constructor(private store: Store<any>) {
    this.loadSettings();
  }

  ngOnDestroy() {
    if (this.storeObserver) {
      this.storeObserver.unsubscribe();
    }
  }

  async loadSettings() {
    this.storeObserver = this.vm$.subscribe(v => {
      this.isNavigationMinified = v.minifyNavigation;
    });
  }

  toggleNavigation() {
    this.store.dispatch(settings.toggleMinifyNavigation());
  }
}
