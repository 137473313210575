import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserRoles } from '../../../+app-custom/constants';
import { AuthenticationService } from '../authentication.service';
import { UserRolePipe } from "../../pipes/user-role.pipe";

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(private userRolePipe: UserRolePipe) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const POSSIBLE_ROLES = UserRoles.All;
    const CURRENT_USER_ROLES = AuthenticationService.getCurrentUser().user.roles;

    const roles = route.data.roles as Array<string>;

    if (!roles) {
      return false;
    }

    return roles.some(role => {
      if (POSSIBLE_ROLES.some(rols => CURRENT_USER_ROLES.includes(rols))) {
        return [role].some(rols => CURRENT_USER_ROLES.includes(rols));
      } else {
        return false;
      }
    });
  }
}
