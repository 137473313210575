import { OpenHourModel } from '../models/open-hour.model';

export function mapDaysOfWeekToOpenHours(openHours: OpenHourModel[]): OpenHourModel[] {
  openHours.forEach(h => {
    if (h && h.day) {
      switch (h.day.toLowerCase().trim()) {
        case 'pondelok': h.dayOfWeek = 1; break;
        case 'utorok': h.dayOfWeek = 2; break;
        case 'streda': h.dayOfWeek = 3; break;
        case 'štvrtok': h.dayOfWeek = 4; break;
        case 'piatok': h.dayOfWeek = 5; break;
        case 'sobota': h.dayOfWeek = 6; break;
        case 'nedeľa': h.dayOfWeek = 7; break;
      }
    }
  });

  return openHours;
}
