import { NgModule } from "@angular/core";
import { NotificationsComponent } from "./notifications.component";
import { CommonModule } from "@angular/common";
import { I18nModule } from "../../template/shared/i18n/i18n.module";
import { AppVersionsNotificationComponent } from './app-versions-notification/app-versions-notification.component';
import { CustomPipesModule } from "../../../+app-custom/pipes/customPipes.module";
import { CommonSharedPipesModule } from "../../pipes/common-shared-pipes.module";

@NgModule({
  exports: [
    NotificationsComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    CustomPipesModule,
    CommonSharedPipesModule
  ],
  declarations: [
    NotificationsComponent,
    AppVersionsNotificationComponent
  ]
})

export class NotificationsModule {}
