import { InjectionToken, ModuleWithProviders, NgModule } from "@angular/core";

import { LanguageSelectorComponent } from './language-selector';
import { I18nPipe } from './i18n.pipe';
import { I18nService } from './i18n.service';
import { CommonModule } from '@angular/common';
import { JsonApiService } from '../../core/api/json-api.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { Routes } from "@angular/router";

export interface TranslateModuleConfig {
  extend?: boolean;
  useDefaultLang?: boolean;
  defaultLanguage?: string;
}

export const USE_EXTEND = new InjectionToken<string>('USE_EXTEND');

@NgModule({
  imports: [CommonModule, BsDropdownModule],
  declarations: [LanguageSelectorComponent, I18nPipe],
  exports: [LanguageSelectorComponent, I18nPipe],
  providers: [I18nService, JsonApiService],
})
export class I18nModule {

  static forRoot(params: TranslateModuleConfig = {}): ModuleWithProviders<I18nModule> {
    return {
      ngModule: I18nModule,
      providers: [
        {provide: USE_EXTEND, useValue: params.extend},
        I18nService
      ]
    };
  }

  static forChild(params: TranslateModuleConfig = {}): ModuleWithProviders<I18nModule> {
    return {
      ngModule: I18nModule,
      providers: [
        {provide: USE_EXTEND, useValue: params.extend},
        I18nService
      ]
    };
  }
}
