import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';

import * as settings from 'src/app/core-frontend-common/template/store/settings';
import { ReportBugService } from "../../../../components/report-bug/report-bug.service";

@Component({
  selector: 'smart-nav-footer',
  templateUrl: './nav-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavFooterComponent {
  constructor(
    private store: Store<any>,
    protected reportBugDialogService: ReportBugService
  ) {}

  toggleMinifyNavigation($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(settings.toggleMinifyNavigation());
  }

  reportProblemClicked(e: Event) {
    e.preventDefault();
    this.reportBugDialogService.reportBug.next(true);
  }
}
