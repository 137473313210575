import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services/user.service';
import { UserModel } from '../models/user.model';

@Pipe({
  name: 'user',
  pure: false,
})
export class UserPipe implements PipeTransform {
  user: UserModel;

  constructor(private _userService: UserService) {}

  transform(value: any): any {
    this._userService.getUserById(value).subscribe(
      res => {
        this.user = res;
        return this.user.name;
      },
      error2 => {
        return 'Nezname';
      },
    );
  }
}
