import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppCommunicationService } from '../../services/app-communication.service';
import { AuthenticationService } from '../../../core-frontend-common/auth/authentication.service';
import { SYSTEM_NAME } from '../../app.settings';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { ExternalUserModel } from '../../models/external-user.model';

@Component({
  selector: 'app-external-user-modal-form',
  templateUrl: './external-user-modal-form.component.html',
  styleUrls: ['./external-user-modal-form.component.scss'],
})
export class ExternalUserModalFormComponent implements OnInit {
  externalUserForm: FormGroup;
  externalUser: ExternalUserModel = new ExternalUserModel();
  dataLoaded = false;
  licenseCheckbox = false;
  wasFormModified = false;

  @Output() externalFormSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() externalUserData: any;
  @Input() newUser = false;
  @Output() submittedUser: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private appCommunicationService: AppCommunicationService,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.fullFillForm();
  }

  fullFillForm() {
    if (!this.newUser) {
      this.externalUserForm = this.formBuilder.group({
        id: [this.externalUser.id],
        title: [this.externalUser.title],
        titleAfter: [this.externalUser.titleAfter],
        name: [this.externalUser.name, [Validators.required]],
        login: [this.externalUser.login],
        surname: [this.externalUser.surname, [Validators.required]],
        employmentCode: [this.externalUser.employmentCode, [Validators.required]],
        phone: [this.externalUser.phone, [Validators.required]],
        birthdate: [this.externalUser.birthdate, [Validators.required]],
        email: [this.externalUser.email, [Validators.required]],
        workCenterName: [this.externalUser.workCenterName, [Validators.required]],
        street: [this.externalUser.street, [Validators.required]],
        postalCode: [this.externalUser.postalCode, [Validators.required]],
        city: [this.externalUser.city, [Validators.required]],
      });
    } else {
      this.externalUserForm = this.formBuilder.group({
        id: [this.externalUser.id],
        title: [this.externalUser.title],
        titleAfter: [this.externalUser.titleAfter],
        name: [this.externalUser.name, [Validators.required]],
        surname: [this.externalUser.surname, [Validators.required]],
        email: [this.externalUser.email, [Validators.required]],
        isExternal: true,
      });
    }

    this.valid.emit(false);

    this.externalUserForm.valueChanges.subscribe(() => {
      this.wasFormModified = true;
      this.checkValidForm();
    });
  }

  checkValidForm() {
    if (this.externalUserForm.valid && this.licenseCheckbox) {
      this.valid.emit(true);
      this.submittedUser.emit(this.externalUserForm.value);
      return true;
    } else {
      this.valid.emit(false);
      return false;
    }
  }

  initUser(user = null) {
    const currentUser = AppCommunicationService.getCurrentUser().user;
    this.externalUser.id = currentUser.userId;
    this.externalUser.title = currentUser.title;
    this.externalUser.titleAfter = currentUser.titleAfter;
    this.externalUser.login = currentUser.login;
    this.externalUser.name = currentUser.name;
    this.externalUser.surname = currentUser.surname;
    this.externalUser.email = currentUser.email;
    this.externalUser.employmentCode = currentUser.employmentCode;
    this.externalUser.workCenterName = currentUser.workCenterName;
    if (user) {
      this.externalUser.phone = user.phone;
      if (user.birthdate) {
        this.externalUser.birthdate = moment(user.birthdate, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY');
      } else {
        this.externalUser.birthdate = '';
      }
      this.externalUser.street = user.street;
      this.externalUser.postalCode = user.postalCode;
      this.externalUser.city = user.city;
      this.externalUser.workCenterName = user.workCenterName;
    }
    this.dataLoaded = true;
    this.fullFillForm();
    this.checkValidForm();
  }

  onNewUserForm() {
    this.dataLoaded = true;
  }

  onSubmitExternalUserForm(): Observable<boolean> {
    const data = this.externalUserForm.value;
    data.birthdate = moment(this.externalUserForm.get('birthdate').value, 'DD.MM.YYYY')
      .toDate()
      .toDateString();

    if (this.wasFormModified) {
      this.userService
        .updateExternalThesisUser(data)
        .pipe(
          flatMap(user => {
            const currentUser = AppCommunicationService.getCurrentUser();
            currentUser.user.userId = user.id;
            const encrypted = AuthenticationService.encryptData(JSON.stringify(currentUser));
            localStorage.removeItem(`${SYSTEM_NAME.toLowerCase()}user`);
            localStorage.setItem(`${SYSTEM_NAME.toLowerCase()}user`, encrypted.toString());
            return this.authenticationService.changeEmployment(user.employmentCode);
          }),
        )
        .subscribe(
          () => {
            this.router.navigate(['/thesis/review']);
          },
          () => {
            this.appCommunicationService.loadingOff();
            return of(false);
          },
        );
    } else {
      this.router.navigate(['/thesis/review']);
      this.appCommunicationService.loadingOff();
    }
    return of(false);
  }
}
