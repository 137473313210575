import { Directive, Input, EventEmitter, ElementRef, Renderer, Inject } from '@angular/core';

@Directive({
  selector: '[focus]',
})
export class FocusDirective {
  @Input('focus') focusEvent: EventEmitter<boolean>;

  constructor(@Inject(ElementRef) private element: ElementRef, private renderer: Renderer) {}

  ngOnInit() {
    this.focusEvent.subscribe(event => {
      this.renderer.invokeElementMethod(this.element.nativeElement, 'focus', []);
      this.element.nativeElement.focus();
    });
  }

  // private focusEmitterSubscription;
  // // Now we expect EventEmitter as binded value
  // @Input('focus')
  // set focus(focusEmitter: EventEmitter<Boolean>) {
  //   if (this.focusEmitterSubscription) {
  //     this.focusEmitterSubscription.unsubscribe();
  //   }
  //   this.focusEmitterSubscription = focusEmitter.subscribe(
  //     (() => this.element.nativeElement.focus()).bind(this))
  // }
  // constructor(@Inject(ElementRef) private element: ElementRef) { }
}
