import { Pipe, PipeTransform } from '@angular/core';
import { UserRoles } from "../../+app-custom/constants";
import { AppCommunicationService } from "../../+app-custom/services/app-communication.service";

@Pipe({
  name: 'userRole',
})
export class UserRolePipe implements PipeTransform {
  static getCurrentUserRoles(): any[] {
    return AppCommunicationService.getCurrentUser().user.roles;
  }

  transform(requestedRoles: string[], reverse?: boolean): any {
    const POSSIBLE_ROLES = UserRoles.All;
    const currentUserRoles = UserRolePipe.getCurrentUserRoles();

    if (!requestedRoles) {
      return;
    }

    if (reverse) {
      return !currentUserRoles.every(role => requestedRoles.includes(role));
    }

    if (typeof requestedRoles === 'string') {
      requestedRoles = [requestedRoles];
    }

    if (POSSIBLE_ROLES.some(roles => currentUserRoles.includes(roles))) {
      return requestedRoles.some(roles => currentUserRoles.includes(roles));
    } else {
      return false;
    }
  }
}
